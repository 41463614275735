import { Injectable } from '@angular/core';
import { SharedRideRequestApiDto } from 'src/app/app-v2/types/apis.type';
import { SharedRideCustomerDetailsService } from './shared-ride-customer-details.service';
import { SharedRideJourneyDetailsService } from './shared-ride-journey-details.service';
import { SharedRidePaymentDetailsService } from './shared-ride-payment-details.service';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';

@Injectable({
  providedIn: 'root'
})
export class SharedRideContainerService {

  sharedRideAllSteps =  {
    JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride', isDisabled: true},
    PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay', isDisabled: true}
    }
  sharedRideRequestDto: SharedRideRequestApiDto | null = null;
  stopsList = null;
  farePrice = 0;
  isEdit  = false;
  editContainer = '';



  constructor(private _sharedRideCustomer: SharedRideCustomerDetailsService,
              private _sharedRideJourney: SharedRideJourneyDetailsService,
              private _sharedRidePayment: SharedRidePaymentDetailsService,
              private _countryFeatureList: CountryFeatureListService
  ) { }

  

  setIsEdit(isEdit){
    this.isEdit = isEdit;
  }

  getIsEdit(){
    return this.isEdit;
  }

  setEditContainer(container){
    this.editContainer = container;
  }

  getEditContainer(){
    return this.editContainer;
  }





  getSharedRideNavigationSteps(){
    let stepTitles = Object.keys(this.sharedRideAllSteps);
    let sharedRideSteps = [];
    sharedRideSteps = stepTitles.map(steps=>this.sharedRideAllSteps[steps]);

    return sharedRideSteps;
   } 


   setPaymentMode(paymentMode){
    this.sharedRideRequestDto = paymentMode;
   }

   getPaymentMode(){
    return this.sharedRideRequestDto.paymentMode;
   }

   getRequestSharedRideDto(){
    
    this.sharedRideRequestDto = {
    businessCode: localStorage.getItem('businessAccountId'),
    businessRideReason: this._sharedRideJourney.getBusinessRideReason(),
    platform: 'WEB',
    paymentMode: this._countryFeatureList.getCountryFeatureList()['defaultActivePaymentMode'].code,
    rideTypeCategory: this._sharedRideJourney.getSelectedSharedRideType(),
    locationDto: this._sharedRideJourney.getSharedRideLocationDto(),
    shareRideScheduleDetailsDto: {
      startDate: this._sharedRideJourney.getStartDate(),
      endDate: this._sharedRideJourney.getEndDate(),
      scheduleAt: `${new Date(this._sharedRideJourney.getStartDate()).getHours()}:${new Date(this._sharedRideJourney.getStartDate()).getMinutes()}`,
      days: this._sharedRideJourney.getDaysOfWeek()
    },
    stopsList: this.stopsList
    }

    this.sharedRideRequestDto.locationDto['pickupNote'] = this._sharedRideCustomer.getAllRidersPickUpNotes();

    return this.sharedRideRequestDto;

   }

   setStopsList(){
    let stopsList = {};
    
    for(let i= 0; i < Object.keys(this._sharedRideJourney.getAllLocationDetails()).length; i++){
      stopsList[`${i}`] = {locationDetails : this._sharedRideJourney.getAllLocationDetails()[`${i}`],
                            ridersList: this._sharedRideCustomer.getAllLocationBasedRiderDetails()[`${i}`]}
    }
    this.stopsList = stopsList;
    console.log(this.stopsList, 'stopslist')
   }

   getStopsList(){
    return this.stopsList;
   }

   setFarePrice(price){
    this.farePrice = price;
   }

   getFarePrice(){
    return this.farePrice;
   }

   clearData(){

    this.sharedRideAllSteps =  {
      JOURNEY_DETAILS: {label: 'Journey Details', isComplete: false, isActive: false, caption: 'Enter pickup/drop and other details regarding your ride', isDisabled: true},
      PAYMENT_DETAILS: {label: 'Payment Details', isComplete: false, isActive: false, caption: 'Review the details and proceed to pay', isDisabled: true}
      }
    this.sharedRideRequestDto = null;
    this.stopsList = null;
    this.farePrice = 0;

   }
}
