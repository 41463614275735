import { Injectable } from '@angular/core';
import { SharedRideCustomerDetails, SharedRideRiderCardType } from 'src/app/app-v2/types/container.type';
import { SharedRideContainerService } from './shared-ride-container.service';
import { CommonService } from '../../common.service';
import { RiderBadgeColorsList } from 'src/app/app-v2/types/enums';
import { CreateRideValidationService } from 'src/app/app-v2/validations/createRide/create-ride-validation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomDropDown } from 'src/app/app-v2/types/components.type';
import { CountryFeatureListService } from 'src/app/app-v2/localization/country-feature-list/country-feature-list.service';

@Injectable({
  providedIn: 'root'
})
export class SharedRideCustomerDetailsService {

  constructor(private _common: CommonService,
              private _createRideValidation: CreateRideValidationService,
              private _loader: NgxSpinnerService,
              private _countryFeatureList: CountryFeatureListService
              
  ) { }
  
  defaultCallingCode:CustomDropDown | null= null;
  isColorUsedHashMap = {};

  locationBasedRiderCardDetails: any = {};
  locationIndexBasedRidersList: any = {}; // rider details organised according to their pick or drop location
  
  addRider(riderDetails: SharedRideCustomerDetails, locationIndex){
    this.addLocationBasedRiderCardDetails(Object.assign({}, riderDetails), locationIndex)
    this.addLocationBasedRiderDetails(Object.assign({}, riderDetails), locationIndex);
  }

  updateRider(riderDetails: SharedRideCustomerDetails, index, locationIndex){
    this.updateRiderCard(riderDetails,index,locationIndex);
    this.updateLocationBasedRiderDetails(riderDetails, index, locationIndex);
  }

  removeRider(index, locationIndex){
    
    this.removeRiderCard(index, locationIndex);
    this.removeLocationIndexBasedRiderDetails(index,locationIndex);
  }

  getAllRidersDetails(){
    let newList = Object.keys(this.locationIndexBasedRidersList).map(locationIndex=>this.locationIndexBasedRidersList[`${locationIndex}`])
    return newList.flat();
  }

  getIndexBasedRiderDetails(riderIndex, locationIndex){
    console.log(this.locationIndexBasedRidersList[`${locationIndex}`][riderIndex])
    return this.locationIndexBasedRidersList[`${locationIndex}`][riderIndex]
  }

  updateRiderCard(details: SharedRideCustomerDetails, index, locationIndex){
    let tempCard: SharedRideRiderCardType = {
      firstName: details.firstName,
      lastName: details.lastName,
      phone: details.phone,
      callingCode: details.callingCode,
      initial: details.firstName.split('')[0].toUpperCase(),
      badgeColor: this.locationBasedRiderCardDetails[`${locationIndex}`][index].badgeColor,
      locationIndex: locationIndex,
      pickupNote: details.pickupNotes
    }
    this.locationBasedRiderCardDetails[`${locationIndex}`][index] = tempCard;
  }

  removeRiderCard(riderIndex, locationIndex){
    let selectedColor = this.locationBasedRiderCardDetails[`${locationIndex}`][riderIndex].badgeColor;
    this.isColorUsedHashMap[selectedColor] = 0;

    this.locationBasedRiderCardDetails[`${locationIndex}`].splice(riderIndex, 1);
  }

  createRiderBadgeHashMap(){
    let allBadgeColor = Object.values(RiderBadgeColorsList);
    allBadgeColor.forEach(color=>{
      this.isColorUsedHashMap[color] = 0;
    })
  }

  getRiderBadgeColor(){
    let allColors = Object.keys(this.isColorUsedHashMap)
    let usedColorsArr = Object.values(this.isColorUsedHashMap);
    let notUsedColorIndex = usedColorsArr.indexOf(0);
    this.isColorUsedHashMap[allColors[notUsedColorIndex]] = 1;
    return allColors[notUsedColorIndex];

  }


  fetchLocationBasedRiderCard(locationIndex){
    return this.locationBasedRiderCardDetails[`${locationIndex}`];
  }

  fetchAllRiderCard(){
    let cardsList = Object.keys(this.locationBasedRiderCardDetails).map(locationIndex=>this.locationBasedRiderCardDetails[`${locationIndex}`]);
    return cardsList.flat();
  }

  getRiderListArrLength(){
    return this.getAllRidersDetails().length;
  }

  isRiderListFilled(){
    return this.getAllRidersDetails().length == this._common.getMaximumSharedRideSeats() ? true : false;
  }

  addLocationBasedRiderCardDetails(details, locationIndex){
    
    let tempCard: SharedRideRiderCardType = {
      firstName: details.firstName,
      lastName: details.lastName,
      phone: details.phone,
      callingCode: details.callingCode,
      initial: details.firstName.split('')[0].toUpperCase(),
      badgeColor: this.getRiderBadgeColor(),
      locationIndex: locationIndex,
      pickupNote: details.pickupNotes
    }

    this.locationBasedRiderCardDetails[`${locationIndex}`] = this.locationBasedRiderCardDetails[`${locationIndex}`] ? [...this.locationBasedRiderCardDetails[`${locationIndex}`], tempCard] : [tempCard];
    
  }

  addLocationBasedRiderDetails(details, index){
    // delete details?.pickupNotes;
    this.locationIndexBasedRidersList[`${index}`] = this.locationIndexBasedRidersList[`${index}`] ? [...this.locationIndexBasedRidersList[`${index}`], details] : [details];
  }

  updateLocationBasedRiderDetails(details, index, locationIndex){
    this.locationIndexBasedRidersList[`${locationIndex}`][index] = details;
  }

  removeLocationIndexBasedRiderDetails(index,locationIndex){
    this.locationIndexBasedRidersList[`${locationIndex}`].splice(index, 1)
  }

  removeAllRiderDetailsBasedOnLocationIndex(locationIndex){
    let ridersListArr = [];
    let ridersListCardArr = [];

    Object.values(this.locationIndexBasedRidersList).forEach((riders,index)=>{
      index != locationIndex ? ridersListArr.push(riders) : null
    })

    Object.values(this.locationBasedRiderCardDetails).forEach((riders:any[],index)=>{
      index != locationIndex ? ridersListCardArr.push(riders) : riders.forEach(rider=>this.isColorUsedHashMap[rider.badgeColor] = 0);
    })

    console.log(ridersListArr, ridersListCardArr);
    this.locationIndexBasedRidersList = {};
    this.locationBasedRiderCardDetails = {};

    ridersListArr.forEach((riders,index)=>{
      this.locationIndexBasedRidersList[`${index}`] = riders;
    })

    ridersListCardArr.forEach((riders, index)=>{
      this.locationBasedRiderCardDetails[`${index}`] = riders;
    })

    console.log(this.locationIndexBasedRidersList, this.locationBasedRiderCardDetails);
  }

  getAllLocationBasedRiderDetails(){
    return this.locationIndexBasedRidersList;
  }

  getAllLocationBasedRiderCardDetails(){
    return this.locationBasedRiderCardDetails;
  }


  setDefaultCallingCode(){
    this._loader.show();
    this.defaultCallingCode = this._countryFeatureList.getCountryFeatureList()['defaultCallingCode'];
    this._loader.hide();
  }

  getDefaultCallingCode(){
    this.setDefaultCallingCode();
    return this.defaultCallingCode;
  }

  getAllRidersPickUpNotes(){
    let pickupNote = '';
    this.getAllRidersDetails().forEach((rider,index)=>{
      if(rider['pickupNote'] == ''){
        pickupNote = pickupNote;
      }
      else{
        pickupNote = pickupNote == '' ? pickupNote : `${pickupNote}, Rider${index+1}: ${rider['pickupNotes']}`
      }
    });

    return pickupNote;
  }

  clearCustomerDetails(){
    this.defaultCallingCode = null;
    this.isColorUsedHashMap = {};

  this.locationBasedRiderCardDetails = {};
  this.locationIndexBasedRidersList = {}; 
  }


  async checkValidation(field: string, value = null, callingCodeValue = null){
    switch(field){
      case 'phoneNumber':
        var x = await this._createRideValidation.validatePhoneNumber(value, callingCodeValue);
        return x;
        
      case 'firstName':
        var x = await this._createRideValidation.validateName(value);
        return x;  
    

      case 'email':
        var x = await this._createRideValidation.validateEmail(value ? value.trim() : null);
        return x;  
    }
  }

}
