
<!-- NOTIFICATION -->
<div class="notification-container"  [@slideOutup300]="animations.notification.slideUpAnimation == 'OUT' ? 'void' : '*'"  *ngIf="ApiData.notifications.length > 0"
                                     [@slideInup300]="animations.notification.slideUpAnimation == 'IN'? '*' : 'void'">
            <notification-bar main [notificationData] = "ApiData.notifications"></notification-bar>
</div>

<div class="main-container">

    <!-- Top Section -->
    <div class="caption-container">
            <span [@slideInleft300]="animations.caption.slideLeftAnimation == 'IN' ? '*' : 'void'"
                  [@slideOutleft300]="animations.caption.slideLeftAnimation == 'OUT' ? 'void' : '*'">
                  <breadcrumb [action]="breadcrumbData"></breadcrumb>
            </span>
            <div class="create-ride-cta-container" [@slideInright300]="animations.caption.slideRightAnimation == 'IN' ? '*' : 'void'" 
                 (click)="createRide()"            [@slideOutright300]="animations.caption.slideRightAnimation == 'OUT' ? 'void' : '*'">
                <custom-button [buttonDetails]="createRidCtaDetails"></custom-button>
            </div>
    </div>


    <div class="dashboard-data-container">
        <div class="left-container">

            <!-- FADE CAROUSEL -->
            <div class="carousel-container" [@fadeIn300]="animations.carousel.fadeAnimation  == 'IN' ? '*' : 'void'"
                                            [@fadeOut300]="animations.carousel.fadeAnimation  == 'OUT' ? 'void' : '*'">
                <carousel [carouselImages]="ApiData.heroCarousel" [speed]="6000" type="FADE"></carousel>
            </div>

            <!-- CREATE RIDE CONTAINER -->
            <div class="create-ride-container" [@slideInleft500]="animations.createRide.fadeAnimation  == 'IN' ? '*' : 'void'"
                 *ngIf="showNewCreateRide"     [@slideOutleft500]="animations.createRide.fadeAnimation  == 'OUT' ? 'void' : '*'">
                <card [type]="'main'">
                    <div main class="select-ride-type-container">
                        <div class="select-ride-type-caption-container">
                            <span class="primary-title">Create Ride</span>
                            
                            <div class="premium-slots">
                                <img src="assets/diamond.svg" alt="">
                                <span>More Premium Slots</span>
                            </div>
                        </div>
                    <select-ride-type-container [showCaption]="false" ></select-ride-type-container>
                    </div>
                </card>
            </div>


            <!-- EXPLORE MORE -->
            <div class="explore-more-container" *ngIf="homepageFeature['explore']">
                <span>Explore More</span>
                <carousel [slideCarouselData]="ApiData.exploreMore" type="SLIDE" align="HORIZONTAL"></carousel>
            </div>


            <!-- PRICING -->
            <!-- <div class="pricing-main-container" [@slideIndown300]="animations.pricing.slideDownAnimation == 'IN' ? '*' : 'void'"
            *ngIf="homepageFeature['pricing']"             [@slideOutdown300]="animations.pricing.slideDownAnimation == 'OUT' ? 'void' : '*'">
                <card type="main">
                <div class="pricing-container" main>
                <div class="pricing-caption-container">
                    <span class="primary-title">Pricing</span>
                    <div class="pricing-service-dropdown-container">
                        <dropdown [dropdownDetails]="pricingServiceRegions"></dropdown>
                    </div>
                    
                    </div>
                        <pricing-container [pricingData]="ApiData.pricingDetails" [selectedZone]="pricingServiceRegions.selectedValue"></pricing-container>
                    </div>

                   
                </card>
            </div> -->


            <!-- SERVICE REGION -->
            <div class="serviceable-region-main-container" [@slideIndown500]="animations.serviceRegion.slideDownAnimation == 'IN' ? '*' : 'void'"
            *ngIf="homepageFeature['serviceRegion']"       [@slideOutdown500]="animations.serviceRegion.slideDownAnimation == 'OUT' ? 'void' : '*'">
                <card type = "main">
                    <div class="serviceable-region-container" main>
                        <div class="serviceable-region-caption-container">
                            <span class="primary-title">Serviceable Regions</span>
                            <div class="serviceable-region-dropdown-container">
                                <dropdown [dropdownDetails]="serviceRegionsDropDown"></dropdown>
                            </div>
                        </div>
                        <serviceable-regions-container [selectedZone]="serviceRegionsDropDown.selectedValue" [allServiceRegions]="ApiData.serviceRegions"> </serviceable-regions-container>
                    </div>
                </card>
            </div>


            <!-- FAQ -->
            <div class="faq-container" [@slideIndown700]="animations.faq.slideDownAnimation == 'IN' ? '*' : 'void'"
            *ngIf="homepageFeature['faq']"  [@slideOutdown700]="animations.faq.slideDownAnimation == 'OUT' ? 'void' : '*'">
                <card type="main">
                    <div class="faq-sub-container" main>
                        <div class="faq-caption-container">
                            <span class="primary-title">Frequently Asked Questions</span>
                        </div>
                        <faq-container [faqList]="ApiData.faqs"></faq-container>
                    </div>
                </card>
            </div>
        </div>



        <div class="right-container">

            <!-- WHAT'S NEW -->
            <div class="whats-new-container" [@slideInright500]="animations.whatsNew.slideRightAnimation == 'IN' ? '*' : 'void'"
            *ngIf="homepageFeature['news']"  [@slideOutright500]="animations.whatsNew.slideRightAnimation == 'OUT' ? 'void' : '*'">
                    
                <card type="main">
                    <div class="whats-new-sub-container" main>
                        <div class="whats-new-caption-container">
                            <div class="caption-container">
                                <img src="assets/news.svg" alt="">
                                <span class="primary-title">What's New</span>
                            </div>
                            
                        </div>
                        <whats-new-container main [newsDetails]="ApiData.news"></whats-new-container>
                    </div>
                </card>
            </div>


            <!-- USEFUL LINKS -->
            <div class="useful-links-container" [@slideInright700]="animations.usefulLinks.slideRightAnimation == 'IN' ? '*' : 'void'"
            *ngIf="homepageFeature['externalLinks']"    [@slideOutright700]="animations.usefulLinks.slideRightAnimation == 'OUT' ? 'void' : '*'">
                    <card type="main">
                        <div class="useful-links-sub-container" main>
                            <div class="useful-links-caption-container">
                                <div class="caption-container">
                                    <img src="assets/useful-link.svg" alt="">
                                    <span class="primary-title">Useful Links</span>
                                </div>
                            </div>
                            <useful-links-container main [usefulLinks]="ApiData.externalLinks"></useful-links-container>
                        </div>
                    </card>
            </div>
        </div>
    </div>
</div>  


<p-dialog [(visible)]="paymentDialog" [modal]="true" [style]="{ width: '28vw', overflow: 'visible', position: 'relative', height: 'auto'}" [draggable]="false" [resizable]="false">
    <img src="assets/close_icon.svg" alt="" style="float: right;" (click)="closePendingPaymentDialog()"><br><br>
    <app-payment-dialog [pendingPaymentProp]="pendingPaymentDialogTransferDto" *ngIf="paymentDialog"></app-payment-dialog>
</p-dialog>
  