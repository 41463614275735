import { Component, OnDestroy, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { SelectRideTypeContainerService } from '../../getter-setter/select-ride-type-container.service';
import { SharedRideContainerService } from '../../getter-setter/container/shared-ride/shared-ride-container.service';
import { BreadCrumbAction, StepperData } from '../../types/components.type';
import { BreadCrumbType, CreateRideSteps, StepperAlign, Theme } from '../../types/enums';
import { RideType } from '../../types/container.type';
import { SharedRideJourneyDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { Subscription } from 'rxjs';
import { CreateSharedRideSubscriptionService } from '../../subscriptions/create-ride/create-shared-ride-subscription.service';
import { CreateRideApiService } from '../../api-services/create-ride/create-ride-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { SharedRidePaymentDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-payment-details.service';

@Component({
  selector: 'create-shared-ride-v2-container',
  templateUrl: './create-shared-ride-container.component.html',
  styleUrls: ['./create-shared-ride-container.component.scss']
})
export class CreateSharedRideV2Component implements OnInit, OnDestroy{
  requestRideSubscription : Subscription ;
  activeStepSubscription : Subscription;

  label = 'Create Ride';
  breadCrumbAction: BreadCrumbAction; // breadCrumb Initialise
  stepperAlignment = StepperAlign.VERTICAL; // stepper alignment
  sharedRideNavigationSteps:StepperData[]; // stepper value intitialise
  activeStepDetails = {title: "" , caption: ""} // selected step title and caption
  selectedActiveStep = {};
  selectedRideTypeData:RideType; 

  constructor(private _router: Router,
              private _selectRideType: SelectRideTypeContainerService,
              private _sharedRide: SharedRideContainerService,
              private _sharedRideJourney: SharedRideJourneyDetailsService,
              private _sharedRideSubscription: CreateSharedRideSubscriptionService,
              private _createRideApi: CreateRideApiService ,
              private _loader: NgxSpinnerService,
              private _sharedRidePayment: SharedRidePaymentDetailsService,
              private _commonSubscription: CommonSubscriptionService
  ){}

  ngOnInit(): void {
    this.setBreadCrumbLabel(BreadCrumbType.ADD, this.label);
    this.setSelectedRideType();
    this.startRequestRideSubscription();
    this.startActiveStepSubscription();
  }


  startActiveStepSubscription(){
    this.activeStepSubscription = this._sharedRideSubscription.fetchCreateSharedRideActiveStepObserver().subscribe(res=>{
      this.setActiveStep(res);
    })
  }


  setSelectedRideType(){
    this.selectedRideTypeData = this._selectRideType.getSelectedRideTypeDetails();
    this.setBreadCrumbLabel(BreadCrumbType.ADD, this.selectedRideTypeData.name);
    this.sharedRideNavigationSteps = this._sharedRide.getSharedRideNavigationSteps(); // recieving no. of steps to create ride 
    this.toggleEnablingActiveStep(CreateRideSteps.JOURNEY_DETAILS, true);
    this.setActiveStep(CreateRideSteps.JOURNEY_DETAILS)
}


toggleEnablingActiveStep(currentStep, val){
  this.sharedRideNavigationSteps.forEach((step,index)=>{
    if(step.label == currentStep){
      step.isDisabled = !val;
    }

    else{
      step.isDisabled = step.isDisabled;
    }
  })
}

  // when click on breadcrumb
  getScreenLabel(data){
    if(data == this.selectedRideTypeData.name){
      return;
    }
    else if(data == this.label){
      this._router.navigate(["/select"]);
      return;
    }
    else{
      this.setActiveStep(data);
      return;
    }
    }

    setActiveStep(currentStep){
      
      let currentStepIndex:number = null;
      if(currentStep == CreateRideSteps.JOURNEY_DETAILS){
        this.sharedRideNavigationSteps.forEach((step,index)=>{
          if(step.label == 'Payment Details'){
            step.isDisabled = true;
          }
          else{
            step.isDisabled = step.isDisabled;        }
        })  
      }
      // loop to switch active step
      this.sharedRideNavigationSteps.forEach((step,index)=>{
        if(step.label == currentStep && !step.isDisabled){
          currentStepIndex = index;
          step.isActive = true;
          this.activeStepDetails = {title: step.label, caption: step.caption}
          this.selectedActiveStep = step;
        }
        else{
          step.isActive = false;
        }
      });
  
  
      // loop to upgrade breadcrumb
      this.sharedRideNavigationSteps.forEach((step,index)=>{
        if(index < currentStepIndex){
          this.setBreadCrumbLabel(BreadCrumbType.ADD, step.label)
          step.isComplete = true
        }
  
        else if(index == currentStepIndex){
          this.setBreadCrumbLabel(BreadCrumbType.ADD, step.label)
        }
  
        else{
          step.isComplete = step.isComplete;
        }});

      }


          // Adding and Removing breadcrumb Label generic function;
    setBreadCrumbLabel(type, item){
      setTimeout(()=>{
        this.breadCrumbAction = { type: type ,item: item};
      },100);
      }

      
  

      startRequestRideSubscription(){
        this.requestRideSubscription = this._sharedRideSubscription.fetchRequestRide().subscribe(res=>{
          if(res){
            let sharedRideDto = this._sharedRide.getRequestSharedRideDto();
              this._loader.show();
              this._createRideApi.requestSharedRide(sharedRideDto).subscribe(res=>{
                this._loader.hide();
                if(res['statusCode'] == 200){
                  this.toggleEnablingActiveStep(CreateRideSteps.PAYMENT_DETAILS, true);
                  this._sharedRidePayment.setRideRequestDetails(sharedRideDto);
                  this._sharedRide.setFarePrice(res['response']['estimatedTotalAmount']);
                  this.setActiveStep(CreateRideSteps.PAYMENT_DETAILS)
                }
                else{
                  this._commonSubscription.emitAlert({
                    theme: Theme.ERROR,
                    title: 'Technical Error !',
                    message: res['message'],
                    ctaLabel: 'OK'
                  })
                }
              });
          }
        })
      }

      ngOnDestroy(): void {
        this._sharedRideJourney.clearData();
        this._sharedRide.clearData();
        this.requestRideSubscription.unsubscribe();
        this.activeStepSubscription.unsubscribe();
      }
}


