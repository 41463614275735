import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { SharedRideCustomerDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { SharedRideJourneyDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { CustomButton, PlacesInputType } from 'src/app/app-v2/types/components.type';
import { SharedRideLocationDetails, SharedRideRiderCardType } from 'src/app/app-v2/types/container.type';
import { ButtonTypes, CustomerDetailsTriggerType } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'shared-ride-add-location-container',
  templateUrl: './shared-ride-add-location-container.component.html',
  styleUrls: ['./shared-ride-add-location-container.component.scss']
})
export class SharedRideAddLocationContainerComponent implements OnChanges{

  @Input() enableAddRiderCta : boolean;
  @Input() locationIndex : number | null;
  @Input() locationType: string
  @Input() showRiderActionCta: boolean
  @Output() locationData = new EventEmitter();
  @Output() riderDetails = new EventEmitter();
  @Output() deleteRider = new EventEmitter<number>();

  showAddRiderCta : boolean = false;
  ridersList:any = [];
  customerDetailsTriggerType: string;
  selectedRiderIndex : number;

  locationDetails: PlacesInputType = {
    placeholder: 'Enter Location..',
    type: 'text',
    inputValue: {
      lat: 0,
      lng: 0,
      place: null,
    },
    label: 'Add Location',
    name: 'LOCATION',
    validation: null,
    isDisabled: false,
    options: { componentRestrictions: { country: this._common.getSelectedCountry() } },
    isAirportEnabled: false,
    isAirportLocation: false,
    isExpressRideEnabled: false,
    isExpressRide: false,
    landmarks: [],
    isLandmarkEnabled: true,
    isLandmarkSelected: [],
    showLandmark: false,
    required: true,
    enableTooltip: false,
    zoneId: 0,
    tooltipContent: ''
  }


  addRiderCta: CustomButton = {
    label: 'Add Rider',
    left: false,
    right: false,
    center: true,
    leftUrl: '',
    rightUrl: '',
    centerUrl: 'assets/add-rider.svg',
    disabled: false,
    type: ButtonTypes.OUTLINED
  }

  toggleAddRiderSidebar: boolean = false;

  constructor(private _common: CommonService,
              private _sharedRideCustomerService: SharedRideCustomerDetailsService,
              private _sharedRideJourneyService: SharedRideJourneyDetailsService
  ){}
  
  
  ngOnChanges(): void {
    
    this.fetchRiderList();
    this.fetchLocationDetails(this.locationIndex == null ? this._sharedRideJourneyService.getSharedRideLocationDto() : this._sharedRideJourneyService.getIndexBasedLocationDetails(this.locationIndex));
    
    this.locationDetails.label = this.locationType == 'PICK' ? 'Pickup Location' : 'Drop Location'
    this.locationDetails.name = this.locationType == 'PICK' ? 'PICKUP' : 'DROP'
    this.showAddRiderCta = this.enableAddRiderCta ? !this._sharedRideCustomerService.isRiderListFilled() : false;
  }


  addLocation(address){
    let locationBasedDto: SharedRideLocationDetails = {
      lat: address.lat,
      lng: address.lng,
      locationName: address.place,
    } 

    this._sharedRideJourneyService.setSelectedZoneId(this.locationDetails.zoneId);
    this.locationData.emit(locationBasedDto);
    this.locationIndex == null ? this._sharedRideJourneyService.setSharedRideLocationDto(locationBasedDto) : this._sharedRideJourneyService.addSharedRideLocation(locationBasedDto, this.locationIndex);
  }


  checkIsInputClear(event){

    let locationBasedDto: SharedRideLocationDetails = {
      lat: this.locationDetails.inputValue.lat,
      lng: this.locationDetails.inputValue.lng,
      locationName: this.locationDetails.inputValue.place,
    } 

    if(locationBasedDto.locationName == ''){
      this.locationData.emit(locationBasedDto);
      this.locationIndex == null ? this._sharedRideJourneyService.setSharedRideLocationDto(locationBasedDto) : this._sharedRideJourneyService.addSharedRideLocation(locationBasedDto, this.locationIndex);
    }
  }

  fetchRiderList(){
    if(this.locationIndex  == null){
      this.ridersList = this._sharedRideCustomerService.fetchAllRiderCard();
    }
    else{
      this.ridersList = [];
      this.ridersList = this._sharedRideCustomerService.fetchLocationBasedRiderCard(this.locationIndex);
    } 
  }

  fetchLocationDetails(address){
    
    if(address){
      this.locationDetails.inputValue = {
        lat: address.lat,
        lng: address.lng,
        place: address.locationName
      }
    }

    else{

      this.locationDetails.inputValue = {
        lat: 0,
        lng: 0,
        place: null
      }
    } 
  }


  toggleAddRiderDialog(){
    this.toggleAddRiderSidebar = !this.toggleAddRiderSidebar;
    this.customerDetailsTriggerType = CustomerDetailsTriggerType.CREATE;
  }

  editCustomerDetails(index){
    this.customerDetailsTriggerType = CustomerDetailsTriggerType.EDIT
    this.selectedRiderIndex = index;
    this.toggleAddRiderSidebar = true;
  }

  deleteCustomerDetails(index){
    this.customerDetailsTriggerType = CustomerDetailsTriggerType.DELETE
    this.selectedRiderIndex = index;
    this._sharedRideCustomerService.removeRider(this.selectedRiderIndex, this.locationIndex);
    this.fetchRiderList();
    this.showAddRiderCta = this.enableAddRiderCta ? !this._sharedRideCustomerService.isRiderListFilled() : false;
    this.deleteRider.emit(index);
  }


  closeAddRiderDialog(event){
    this.toggleAddRiderSidebar = false;
    this.fetchRiderList();
    this.showAddRiderCta = !this._sharedRideCustomerService.isRiderListFilled();
    this.riderDetails.emit(this.ridersList);
  }
}
