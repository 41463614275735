import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class BadgeComponent implements OnChanges{

  @Input() badgeType: string;
  @Input() badgeColor: string;
  @Input() badgeText: string;


  ngOnChanges(changes: SimpleChanges): void {
    
  }
}
