import { Component, OnDestroy, OnInit } from '@angular/core';
import { EditableContainerComponent } from '../../components/editable-container/editable-container.component';
import { CustomButton, EditableContainerType } from '../../types/components.type';
import { PaymentDetailsContainerService } from '../../getter-setter/container/create-ride/payment-details-container.service';
import { RequestRideApiResponse } from '../../types/apis.type';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { CommonService } from '../../getter-setter/common.service';
import { PaymentModesService } from '../../getter-setter/container/payment-modes/payment-modes.service';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SelectRideTypeContainerService } from '../../getter-setter/select-ride-type-container.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { JourneyDetailsContainerService } from '../../getter-setter/container/create-ride/journey-details-container.service';
import { CountryFeatureListService } from '../../localization/country-feature-list/country-feature-list.service';
import { ButtonTypes, CreateRideSteps } from '../../types/enums';

@Component({
  selector: 'payment-details-container',
  templateUrl: './payment-details-container.component.html',
  styleUrls: ['./payment-details-container.component.scss']
})
export class PaymentDetailsContainerComponent implements OnInit, OnDestroy{
rideRequestDetails: RequestRideApiResponse;
closeDialogSubscription: Subscription;

carVariantDetails = [];
paymentModes;
selectedPaymentModes = [];
selectedPayment = "";
selectedCarVariantCategory = "";
isTncRequired = false;

isCarVariantContainerRequired = false;
isPickupNotesContainerRequired = false;
isExpressRide = false;

tncCheckboxDetails ={
  disabled: false,
  checked: false,
}

paymentModeCta:CustomButton  = {
    label: 'Confirm Booking',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true,
    type: ButtonTypes.FILLED
}

constructor(private _payment: PaymentDetailsContainerService,
            private _journey: JourneyDetailsContainerService,
            private _paymentMode: PaymentModesService,
            private _createRide: CreateRideService,
            private _common: CommonService,
            private _subscription : CreateRideSubscriptionService,
            private _countryFeature: CountryFeatureListService,
            private _commonSubscription: CommonSubscriptionService,
            private _rideType: SelectRideTypeContainerService,
            private _router: Router
){}

ngOnInit(): void {
  this._createRide.setPaymentMode(this._countryFeature.getCountryFeatureList()['defaultActivePaymentMode'].code);
  this.rideRequestDetails = this._payment.getRideRequestDetails();
  this.isTncRequired = this._rideType.getIsTncRequired();
  this.carVariantDetails = this.checkSelectedCarVariant();  
  this.paymentModes = this._payment.getAllowedPaymentModesDetails();
  this.rideRequestDetails.rideDetails.rideType == 'SCHEDULED_RENTALS' ? delete this.paymentModes['CASH'] : null;

  this.isCarVariantContainerRequired = this._rideType.getIsAirportRide() || this._rideType.getIsPackageRequired() ? true : false;
  this.isExpressRide = this._createRide.getIsExpressRide();
  this.isPickupNotesContainerRequired = this.checkIfPickupNotesContainerRequired(); 
  this.getSelectedPaymentModes();
  this.startCloseDialogSubscription();
  this.checkActivePaymentMode(); 
}


checkSelectedCarVariant(){
    this.selectedCarVariantCategory = this._journey.getSelectedCarVariantCategory();
    if(this.selectedCarVariantCategory == 'PREMIUM'){
      return this._common.getAllCarvariantDetails().filter(carVariant=>carVariant.category == 'PREMIUM');
    }
    else{
      return this._common.getAllCarvariantDetails();
    }
} 

checkIfPickupNotesContainerRequired(){
  let otherFlagsDetails = this.rideRequestDetails.rideDetails.otherFlags;
  let pickupNotes = this.rideRequestDetails?.pickupNotes?.notes == null || this.rideRequestDetails?.pickupNotes?.notes == '' ? false : true;
  let businessRideReason = otherFlagsDetails.businessRideReason == null || otherFlagsDetails.businessRideReason == '' ? false : true;
  let expenseCode = otherFlagsDetails.expenseCode == null || otherFlagsDetails.expenseCode == '' ? false : true;

  if(pickupNotes || businessRideReason || expenseCode){
    return true;
  }
  else{
    return false
  }
}

getSelectedPaymentModes(){
  
  this.selectedPaymentModes = Object.keys(this.paymentModes).map(paymentMode=>{
    return this.paymentModes[paymentMode];
  })

  this.selectedPaymentModes.forEach(mode => {
    mode.isActive = false;
  });

  let ifIsAnyPaymentModeActive = this.selectedPaymentModes.map(paymentMode=>this.paymentModes.isActive);
  if(!ifIsAnyPaymentModeActive.includes(true)){
    this.selectedPaymentModes[0].isActive = true;
    this.selectedPayment = this.selectedPaymentModes[0].code;
    this._createRide.setPaymentMode(this.selectedPayment);
    this.checkActivePaymentMode();
  }
  else{
    return;
  }
}

selectPaymentMode(index){
  this.selectedPaymentModes.forEach(mode => {
    mode.isActive = false;
  });

  this.selectedPaymentModes[index].isActive = true;
  this.selectedPayment = this.selectedPaymentModes[index].code;
  this._createRide.setPaymentMode(this.selectedPayment ? this.selectedPayment : this._countryFeature.getCountryFeatureList()['defaultActivePaymentMode'].code);
  this.checkActivePaymentMode();
}


submitPaymentMode(){
this._paymentMode.setSelectedPaymentMode(this.selectedPayment, 'CREATE_RIDE');
}


startCloseDialogSubscription(){
  this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
    if(res[0]){
      this._router.navigate(["/rides/schedulerides"])
    }
  })
}

checkActivePaymentMode(){
  let activePaymentMode = Object.keys(this.paymentModes).map(paymentMode=>{
    if(this.paymentModes[paymentMode]['isActive']){
      this.selectedPayment = this.paymentModes[paymentMode]['code'];
      return this.paymentModes[paymentMode]['isActive'];
    }
    else{
      return this.paymentModes[paymentMode]['isActive'];
    }
  });
  this.paymentModeCta.disabled = activePaymentMode.includes(true) && this.checkIfTncChecked() ? false : true;
}


checkIfTncChecked(){
  return this.isTncRequired ? this.tncCheckboxDetails.checked : true;
}


editData(type){
    this._createRide.setSelectedJourneyDetailsStep(type);
    this._subscription.emitCreateRideActiveStepObserver(CreateRideSteps.JOURNEY_DETAILS);
}

moreTncDetails(){
  const url = this._router.serializeUrl(this._router.createUrlTree(['/info']));
  window.open(url, '_blank');
}

ngOnDestroy(): void {
  this.closeDialogSubscription.unsubscribe();
}


}
