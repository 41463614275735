<div class="main-container" >
    <breadcrumb [action]="breadCrumbAction" (getLabel)="getScreenLabel($event)"></breadcrumb>

    <div class="create-ride-container">
    <card type="main" >
        <div main class="create-ride-step-container">
            <div class="stepper-container">
                <stepper [align]="stepperAlignment" [stepperData]="sharedRideNavigationSteps" (fetchStepLabel)="setActiveStep($event)"></stepper>
            </div>

            <div class="details-container"> 
                <div class="active-step-title">
                    <h1>{{activeStepDetails.title}}</h1>
                    <p>{{activeStepDetails.caption}}</p>
                </div>

                <div class="active-container">
                    <shared-ride-journey-details-container *ngIf="selectedActiveStep['label'] == 'Journey Details'"></shared-ride-journey-details-container>
                    <shared-ride-payment-details-container *ngIf="selectedActiveStep['label'] == 'Payment Details'"></shared-ride-payment-details-container>
                </div>
            </div>  
        </div>
    </card>
    </div>
</div>