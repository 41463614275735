import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { PopupType } from '../../types/components.type';
import { PopUpComponent } from '../../components/pop-up/pop-up.component';

@Injectable({
  providedIn: 'root'
})
export class CommonSubscriptionService {
  alertPopupTrigger = new Subject<PopupType>();
  closeAlertDialog = new Subject<[boolean, string]>();
  delayAlertNotification = new Subject<boolean>();


  constructor() { 
  }

  emitAlert(popupDetails: PopupType){
    this.alertPopupTrigger.next(popupDetails);
  }

  fetchAlert(){
   return  this.alertPopupTrigger.asObservable();
  }

  emitCloseAlert(isSuccess, theme = ''){
    this.closeAlertDialog.next([isSuccess, theme]);
  }

  fetchCloseAlert(){
   return  this.closeAlertDialog.asObservable();
  }

  emitDelayAlert(alert){
    this.delayAlertNotification.next(alert);
  }

  fetchDelayAlert(){
    return this.delayAlertNotification.asObservable();
  }
}
