import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateRideService } from './container/create-ride/create-ride-container.service';
import { CommonService } from './common.service';
import { SelectRideTypeContainerService } from './select-ride-type-container.service';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';
import { DateTimePipe } from '../pipe/createridepipe.pipe';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [CreateRideService, CommonService, SelectRideTypeContainerService, CountryFeatureListService, DateTimePipe]
})
export class GetterSetterModule { }
