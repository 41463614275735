<div class="main-container">    
    <div class="left-section">
        
        <!-- Location Container -->
            <card type="container">
                <div class="location-container" container>
                    
                    <div class="title-container">
                        <span class="title">Location</span>
                        <div class="edit-container" (click)="editData('LOCATION')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Location</span>
                        </div>
                    </div>

                    <div class="data-container">
                        <div class="pickup-location-container" *ngFor="let location of pickupLocationArr">
                            <div class="icon-container">
                                <img src="assets/pick.svg" alt="">
                            </div>

                            <div class="shared-pick-location-container">
                                <span class="text-info">{{location.locationName}}</span>
                                <div class="rider-container" *ngIf="location['ridersList'] && rideRequestDetails.rideTypeCategory == 'ONE_DROP_MULTIPLE_PICKUPS'">
                                    
                                    <div class="rider-details-container" *ngFor="let rider of location['ridersList']">
                                        <div class="badge-container">
                                            <badge [badgeType]="'ROUND'" [badgeColor]="rider.badgeColor" [badgeText]="rider.initial"></badge>
                                        </div>
                                        
                                        <span class="rider-name">{{rider.firstName}}{{rider.lastName}}</span>
                                    </div>
                                </div>
                            </div>
                        
                        </div>


                        <div class="drop-location-container" *ngFor="let location of dropLocationArr">
                            <div class="icon-container">
                                <img src="assets/drop.svg" alt="">
                            </div>
                            
                            <div class="shared-drop-location-container">
                            <span class="text-info">{{location.locationName}}</span>

                            <div class="rider-container" *ngIf="location['ridersList'] && rideRequestDetails.rideTypeCategory == 'ONE_PICKUP_MULTIPLE_DROPS'">
                                <div class="rider-details-container" *ngFor="let rider of location['ridersList']">
                                    <div class="badge-container">
                                        <badge [badgeType]="'ROUND'" [badgeColor]="rider.badgeColor" [badgeText]="rider.initial"></badge>
                                    </div>
                                    
                                    <span class="rider-name">{{rider.firstName}}{{rider.lastName}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </card>
    

            <!-- Additional Details Container -->
            <card type="container" *ngIf="rideRequestDetails?.locationDto?.pickupNote || rideRequestDetails.businessRideReason">
                <div class="additional-details-container" container>
                    <div class="title-container">
                        <span class="title">Pick up Notes & Business Ride Reason</span>
                        <div class="edit-container" (click)="editData('LOCATION')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Notes</span>
                        </div>
                    </div>

                    <div class="additional-details-data-container">
                            <div class="icon-container" *ngIf="showPickupNotes">
                                <img src="assets/shared-ride-calendar.svg" alt="" class="pickup-notes-icon">
                            </div>

                            <div class="data-sub-container">
                            <div *ngFor="let notes of pickupNotesArr" style="width: 100%;">
                                <div class="pickup-notes-container" *ngIf="notes?.pickupNote">                            
                                <div class="notes-title-container">
                                    <div class="badge-container">
                                        <badge [badgeType]="notes?.badgeType" [badgeColor]="notes?.badgeColor" [badgeText]="notes?.badgeText"></badge>
                                    </div>
                                    <span class="rider-name">{{notes?.name}}</span>
                                </div>
    
                                <div class="icon-container" *ngIf="notes?.pickupNote">
                                    <img src="assets/pickupNotes.svg" alt="" class="pick-up-note-icon">
                                    <span class="text-info">{{notes?.pickupNote}}</span>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>


                    <div class="business-ride-reason-main-container" *ngIf="rideRequestDetails?.businessRideReason" container>
                        <div class="icon-container">
                            <img src="assets/brrAndExpense.svg" alt="" class="pick-up-note-icon">
                        </div>
    
                        <div class="business-ride-reason-container">
                            <span class="text-info">{{rideRequestDetails?.businessRideReason}}</span>
                        </div>
                        
                    </div>
                </div>        


                


                <!-- <div class="expense-code-container" *ngIf="rideRequestDetails?.rideDetails?.otherFlags?.expenseCode">
                    <div class="icon-container">
                        <img src="assets/expense_code.svg" alt="">
                    </div>
                    <span class="text-info">{{rideRequestDetails?.rideDetails?.otherFlags?.expenseCode}}</span>
                </div> -->
            </card>
        

         <!-- Date & Time Container    -->
            <card type="container">
                <div class="timetamp-container" container>
                    <div class="title-container">
                        <span class="title">Date & Time</span>
                        
                        <div class="edit-container" (click)="editData('TIME')">
                            <img src="assets/edit.svg" alt="">
                            <span class="edit-text">Edit Time</span>
                        </div>
                    </div>

                <div class="data-container">
                    <div class="date-container">
                        <div class="icon-container">
                            <img src="assets/shared-ride-calendar.svg" alt="">
                        </div>

                        <div class="info-main-container">
                        <div class="info-sub-container">
                            <span class="text-title">Start Date</span>
                            <span class="text-time-info">{{rideRequestDetails.shareRideScheduleDetailsDto.startDate | onlyDate}}</span>
                        </div>

                        <div class="info-sub-container">
                            <span class="text-title">End Date</span>
                            <span class="text-time-info">{{rideRequestDetails.shareRideScheduleDetailsDto.endDate | onlyDate}}</span>
                        </div>

                        <div class="info-sub-container" *ngIf="rideRequestDetails?.shareRideScheduleDetailsDto?.days.length > 0">
                            <span class="text-title">Days of Week</span>
                            <span class="text-time-info">{{rideRequestDetails.shareRideScheduleDetailsDto.days | daysOfWeekShort}}</span>
                        </div>
                    </div>
                    </div>


                    <div class="time-container">
                        <div class="icon-container">
                            <img src="assets/shared-ride-clock.svg" alt="">
                        </div>

                        <div class="info-sub-container">
                            <span class="text-title">Time</span>
                            <span class="text-time-info">{{rideRequestDetails.shareRideScheduleDetailsDto.startDate | onlyTime}}</span>
                        </div>
                    </div>
                </div>
                </div>                
            </card>
    </div>


    <div class="right-section">
    
    <!-- Payment Container -->
        <card type="container">
            <div class="payment-summary-container" container>
            <div class="title-container">
                <span class="title">Payment Summary</span>
            </div>

              <div class="payment-details-container">
                <!-- <div class="detail-container">
                    <span class="details">Distance</span>
                    <span class="details">{{(rideRequestDetails.rideDetails.distance / 1000).toFixed(2)}} Km</span>
                </div> -->

                <div class="detail-container">
                    <span class="details">Fare</span>
                    <span class="details">{{farePrice | currencypipe}}</span>
                </div>

                <!-- <div class="detail-container">
                    <span class="details">Airport Charges & Tolls</span>
                    <span class="details">{{rideRequestDetails?.pricingDetails?.estimatedAdditionalAmountExclTax | currencypipe}}</span>
                </div> -->

                <!-- <div class="detail-container">
                    <span class="details">Tax</span>
                    <span class="details">{{rideRequestDetails.pricingDetails.estimatedTaxAmount | currencypipe}}</span>
                </div> -->

              </div>  

              <div class="total-fare-container">
                <span class="total-fare-details">Total Fare</span>
                <span class="total-fare-details">{{farePrice | currencypipe}}</span>
              </div>        
        </div>
        </card>
        

        <!-- Payment Modes Container -->
        <div class="payment-method-container">
            <div class="title-container">
                <span class="title">Payment Method</span>
            </div>
            

            <div class="payment-container">
            <div class="payment-methods" *ngFor="let paymentMode of selectedPaymentModes; index as i" (click)="selectPaymentMode(i)">
                <card type="container" [isActive]="paymentMode.isActive">
                    <div class="mode-container" container>
                        <img [src]="paymentMode['iconUrl']" alt="">
                        <div class="mode-detail-container">
                            <span class="payment-title">{{paymentMode.title}}</span>
                            <span class="payment-desc">{{paymentMode.desc}}</span>
                        </div>
                        
                    </div>
                </card>
            </div>
        </div>
        </div>

    <!-- Book ride Cta Container -->
        <div class="confirm-cta-container">
            <custom-button [buttonDetails]="paymentModeCta" (onCustomButtonClick)="submitPaymentMode()"></custom-button>
        </div>
    </div>
</div> 