import { Component, OnInit } from '@angular/core';
import { SharedRideRequestApiDto } from '../../types/apis.type';
import { SharedRideContainerService } from '../../getter-setter/container/shared-ride/shared-ride-container.service';
import { Subscription } from 'rxjs';
import { SharedRidePaymentDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-payment-details.service';
import { SharedRideJourneyDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { PaymentModesService } from '../../getter-setter/container/payment-modes/payment-modes.service';
import { CommonService } from '../../getter-setter/common.service';
import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { CountryFeatureListService } from '../../localization/country-feature-list/country-feature-list.service';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { Router } from '@angular/router';
import { CustomButton } from '../../types/components.type';
import { ButtonTypes, SharedRideTypes, BadgeTypes, CreateRideSteps } from '../../types/enums';
import { SharedRideCustomerDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { CreateSharedRideSubscriptionService } from '../../subscriptions/create-ride/create-shared-ride-subscription.service';

@Component({
  selector: 'shared-ride-payment-details-container',
  templateUrl: './shared-ride-payment-details-container.component.html',
  styleUrls: ['./shared-ride-payment-details-container.component.scss']
})
export class SharedRidePaymentDetailsContainerComponent implements OnInit{
  rideRequestDetails: SharedRideRequestApiDto;
  closeDialogSubscription: Subscription;
  
  paymentModes;
  selectedPaymentModes = [];
  selectedPayment = "";

  pickupLocationArr = [];
  dropLocationArr = [];
  pickupNotesArr = [];

  farePrice = 0;
  showPickupNotes = false;
  
  paymentModeCta:CustomButton  = {
      label: 'Confirm Booking',
      left: false,
      right: false,
      leftUrl: '',
      rightUrl: '',
      disabled: true,
      type: ButtonTypes.FILLED
  }
  
  constructor(private _payment: SharedRidePaymentDetailsService,
              private _journey: SharedRideJourneyDetailsService,
              private _customer: SharedRideCustomerDetailsService,
              private _paymentMode: PaymentModesService,
              private _sharedRide: SharedRideContainerService,
              private _common: CommonService,
              private _subscription : CreateSharedRideSubscriptionService,
              private _countryFeature: CountryFeatureListService,
              private _commonSubscription: CommonSubscriptionService,
              private _router: Router
  ){}
  
  ngOnInit(): void {
    this._common.scrollTop();
    this._sharedRide.setPaymentMode(this._countryFeature.getCountryFeatureList()['defaultActivePaymentMode'].code);
    this.rideRequestDetails = this._payment.getRideRequestDetails();
    this.paymentModes = this._payment.getAllowedPaymentModesDetails();
    this.farePrice = this._sharedRide.getFarePrice();
    
    this.setPickupLocation();
    this.setDropLocation();
    this.setPickupNotes();
    
    // this.isPickupNotesContainerRequired = this.checkIfPickupNotesContainerRequired(); 
    this.getSelectedPaymentModes();
    this.startCloseDialogSubscription();
    this.checkActivePaymentMode(); 
  
  }


  setPickupLocation(){
    if(this.rideRequestDetails.rideTypeCategory == SharedRideTypes.SHARED_DROP){
      this.pickupLocationArr.push(this.rideRequestDetails.locationDto)
    }

    else{
      let locationIndexes =  Object.keys(this.rideRequestDetails.stopsList);
      let ridersList = this._customer.getAllLocationBasedRiderCardDetails();

      locationIndexes.forEach(location=>{
        this.pickupLocationArr.push({
          locationName: this.rideRequestDetails.stopsList[`${location}`]['locationDetails']['locationName'],
          ridersList: ridersList[`${location}`]
        });
      })
    }
  }

  setDropLocation(){
    if(this.rideRequestDetails.rideTypeCategory == SharedRideTypes.SHARED_PICKUP){
      this.dropLocationArr.push(this.rideRequestDetails.locationDto);
    }

    else{
      let locationIndexes =  Object.keys(this.rideRequestDetails.stopsList);
      let ridersList = this._customer.getAllLocationBasedRiderCardDetails();

      locationIndexes.forEach(location=>{
        this.dropLocationArr.push({
          locationName: this.rideRequestDetails.stopsList[`${location}`]['locationDetails']['locationName'],
          ridersList: ridersList[`${location}`]
        });
      })
    }
  }
  
  setPickupNotes(){
    let riderDetails = this._customer.fetchAllRiderCard();

    this.pickupNotesArr = riderDetails.map(rider=>{
      return ({badgeType: BadgeTypes.ROUND, 
              badgeColor: rider['badgeColor'], 
              badgeText: rider['initial'],
              name: rider['firstName'] + rider['lastName'],
              pickupNote: rider['pickupNote']})
    });

    let tempPickupNotesArr = this.pickupNotesArr.filter(notes=>notes.pickupNote != '');
    console.log(tempPickupNotesArr)
    this.showPickupNotes = tempPickupNotesArr.length > 0;
  }

  
  getSelectedPaymentModes(){
    console.log(this.paymentModes);
    this.selectedPaymentModes = Object.keys(this.paymentModes).map(paymentMode=>{
      return this.paymentModes[paymentMode];
    })
  
    this.selectedPaymentModes.forEach(mode => {
      mode.isActive = false;
    });
  
    let ifIsAnyPaymentModeActive = this.selectedPaymentModes.map(paymentMode=>this.paymentModes.isActive);
    if(!ifIsAnyPaymentModeActive.includes(true)){

      this.selectedPaymentModes[0].isActive = true;
      this.selectedPayment = this.selectedPaymentModes[0].code;
      this._sharedRide.setPaymentMode(this.selectedPayment);
      this.checkActivePaymentMode();
    }
    else{
      return;
    }
  }
  
  selectPaymentMode(index){
    this.selectedPaymentModes.forEach(mode => {
      mode.isActive = false;
    });
  
    this.selectedPaymentModes[index].isActive = true;
    this.selectedPayment = this.selectedPaymentModes[index].code;
    this._sharedRide.setPaymentMode(this.selectedPayment ? this.selectedPayment : this._countryFeature.getCountryFeatureList()['defaultActivePaymentMode'].code);
    this.checkActivePaymentMode();
  }
  
  
  submitPaymentMode(){
  this._paymentMode.setSelectedPaymentMode(this.selectedPayment, 'SHARED_RIDE');
  }
  
  
  startCloseDialogSubscription(){
    this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
      if(res[0]){
        this._router.navigate(["/rides/schedulerides"])
      }
    })
  }
  
  checkActivePaymentMode(){
    let activePaymentMode = Object.keys(this.paymentModes).map(paymentMode=>{
      if(this.paymentModes[paymentMode]['isActive']){
        this.selectedPayment = this.paymentModes[paymentMode]['code'];
        return this.paymentModes[paymentMode]['isActive'];
      }
      else{
        return this.paymentModes[paymentMode]['isActive'];
      }
    });
    this.paymentModeCta.disabled = activePaymentMode.includes(true) ? false : true;
  }
  
  
  editData(type){
      this._sharedRide.setIsEdit(true);
      this._sharedRide.setEditContainer(type);
      this._subscription.emitCreateSharedRideActiveStepObserver(CreateRideSteps.JOURNEY_DETAILS);
  }
  

  
  ngOnDestroy(): void {
    this.closeDialogSubscription.unsubscribe();
  }
  
}
