export const BreadCrumbType = {
    ADD : 'ADD',
    REMOVE: 'REMOVE'
}


export const StepperAlign = {
    HORIZONTAL : 'HORIZONTAL',
    VERTICAL : 'VERTICAL'
}


export const Theme = {
    ERROR : 'ERROR',
    WARNING: 'WARNING',
    INFO: 'INFO',
    SUCCESS: 'SUCCESS',
    CUSTOM: 'CUSTOM'
}


export const CarouselAlignmnet = {
    VERTICAL : 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
}


export const CreateRideSteps = {
    CUSTOMER_DETAILS: 'Customer Details',
    JOURNEY_DETAILS: 'Journey Details',
    WEEK_SCHEDULE: 'Week Schedule',
    PAYMENT_DETAILS: 'Payment Details'
}

export const JourneyDetailsStep = {
    LOCATION_DETAILS: 'locationDetails',
    CAR_DETAILS: 'carVariantDetails',
    TIMESTAMP: 'timestampDetails',
}

export const ButtonTypes = {
    FILLED : 'FILLED',
    OUTLINED: 'OUTLINED' 
}

export const SharedRideTypes ={
    SHARED_PICKUP : "ONE_DROP_MULTIPLE_PICKUPS",
    SHARED_DROP : "ONE_PICKUP_MULTIPLE_DROPS"
}

export const CustomerDetailsTriggerType = {
    EDIT : 'EDIT',
    CREATE : 'CREATE',
    DELETE : 'DELETE'
}

export const BadgeTypes = {
    ROUND : 'ROUND'
}

export const RiderBadgeColorsList = {
    0: '#5A84F0',
    1: '#F0AF5A',
    2: '#825AF0',
    3: '#F05AE8'
}

export const DaysOfWeek = {
    SUNDAY : 'SUN',
    MONDAY : 'MON',
    TUESDAY: 'TUE',
    WEDNESDAY:'WED',
    THURSDAY:'THU',
    FRIDAY: 'FRI',
    SATURDAY:'SAT'
}
