import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AccordianDetails } from '../../types/components.type';

@Component({
  selector: 'accordian',
  templateUrl: './accordian.component.html',
  styleUrls: ['./accordian.component.scss']
})
export class AccordianComponent implements OnChanges, AfterViewInit{

  @Input() accordianDetails: AccordianDetails
  @Input() accordianType: string;
  @Input() HTMLContent: string | null;
  @Input() highlight: boolean;

  @Output() isActiveEvent= new EventEmitter<boolean>();
  @Output() deleteAcc = new EventEmitter<boolean>();
  @ViewChild('headerTemplate') accordianHeaderTemplate! : ElementRef;
  
  ngOnChanges(changes: SimpleChanges): void {
      this.updateHTMLContent();
  }

  ngAfterViewInit(){
    this.accordianHeaderTemplate?.nativeElement ? this.accordianHeaderTemplate.nativeElement.innerHTML = this.HTMLContent : null;
  }

  updateHTMLContent(){
    if (this.accordianHeaderTemplate && this.accordianHeaderTemplate.nativeElement) {
      this.accordianHeaderTemplate.nativeElement.innerHTML = this.HTMLContent;
    }
  }


  toggleAccordian(){
    if(!this.accordianDetails.isDisabled){
      this.accordianDetails.isActive = !this.accordianDetails.isActive;
      this.isActiveEvent.emit(this.accordianDetails.isActive);
    }
    else{
      return "";
    }
  }

  deleteAccordian(){
    this.deleteAcc.emit(true);
  }
}
