import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { SharedRideContainerService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-container.service';
import { SharedRideCustomerDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { SharedRideJourneyDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { CommonSubscriptionService } from 'src/app/app-v2/subscriptions/common/common-subscription.service';
import { CreateSharedRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-shared-ride-subscription.service';
import { CustomButton, AccordianDetails, TabDetails, CustomDropDownType, CustomInputType } from 'src/app/app-v2/types/components.type';
import { SharedRideLocationDetails } from 'src/app/app-v2/types/container.type';
import { ButtonTypes, SharedRideTypes, Theme } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'shared-pickup-container',
  templateUrl: './shared-pickup-container.component.html',
  styleUrls: ['./shared-pickup-container.component.scss']
})
export class SharedPickupContainerComponent implements OnDestroy, OnChanges, OnInit{

  @Output() changeStep = new EventEmitter<boolean>();
  @Input() businessRideReasons = {};
  @Input() expenseCode = {};

  closeDialogSubscription : Subscription;
  showAddLocationCtaSubscription : Subscription;
  sharedRideType:string = SharedRideTypes.SHARED_PICKUP;
  showOtherInputBox = false;
  additionalDetailsAcc: AccordianDetails = {
    label: 'Additional Details',
    isActive: true,
    isComplete: false,
    isVisible: false,
    isDisabled: false,
    showDeleteIcon :  false
  }

  submitLocationCtaDetails: CustomButton ={
    label: 'Next',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true,
    type: ButtonTypes.FILLED
  }

  addLocationCtaDetails: CustomButton ={
    label: 'Add Location +',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: true,
    type: ButtonTypes.OUTLINED
  }

  locationTabs:TabDetails[] = [{
    label: 'Pickup Location',
    value: "PICKUP",
    showIcon: true,
    url: "assets/pick.svg",
    isSelected: true,
    isDisabled: false,
    
},

{
  label: 'Drop Location',
  value: "DROP",
  showIcon: true,
  url: "assets/drop.svg",
  isSelected: false,
  isDisabled: false,
  
}]; 

dropLocationAccDetails: AccordianDetails = {
  label: 'Drop Location',
  isActive: true,
  isComplete: false,
  isVisible: true,
  isDisabled: false,
  showDeleteIcon: false
} ;


businessRideReasonInputDetails: CustomInputType = {
  placeholder : 'For eg. Late night cab, administrative commute etc',
  type: 'text',
  inputValue: null,
  label: 'Manually add reason',
  name: 'businessRideReason',
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Add purpose of your ride as specified by your organisation'
}

expenseCodeInputDetails: CustomInputType = {
  placeholder : 'For eg. Late night cab, administrative commute etc',
  type: 'text',
  inputValue: null,
  label: 'Manually add Expense Code',
  name: 'expenseCode',
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Please add the code given by your organization'
}

businessRideReasonDropDownDetails : CustomDropDownType = {
  selectedValue: {label: '', value: ''},
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  options: [],
  label: 'Business Ride Reason',
  name: 'businessRideReason',
  placeholder : 'Select Reason',
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Add purpose of your ride as specified by your organisation'
}

expenseCodeDropDownDetails : CustomDropDownType = {
  selectedValue: {label: '', value: ''},
  validation: {
    isError: false,
    isSuccess: false,
    message: ''
  },
  options: [],
  label: 'Expense Code',
  name: 'expenseCode',
  placeholder : 'Select Code',
  isDisabled: false,
  required: false,
  enableTooltip: true,
  tooltipContent: 'Please add the code given by your organization'
}


  selectedTab:string = 'PICKUP';
  riderMaxCapacity : number = 0;
  pickupLocationMaxCapacity: number = 0;
  dropLocationMaxCapacity: number = 0;
  showAddLocationCta:boolean = false; 
  locationAccCountArr: string[] = [];
  locationAccDetails: AccordianDetails[] = [];
  locationDetailsArr = [];
  locationDto: SharedRideLocationDetails;
  riderDetailsArr = [];

  deleteIndex = 0;
  

  constructor(private _common: CommonService,
              private _sharedRide: SharedRideContainerService,
              private _sharedRideJourney: SharedRideJourneyDetailsService,
              private _sharedRideCustomer: SharedRideCustomerDetailsService,
              private _commonSubscription: CommonSubscriptionService,
              private _sharedRideSubscriptionService: CreateSharedRideSubscriptionService){}
  
  ngOnChanges(changes: SimpleChanges): void {
    this.setAdditionalDetails();  
  }

 

  ngOnInit(): void {
    this.startCloseDialogSubscription();
    this._sharedRideCustomer.createRiderBadgeHashMap()
    this.startShowAddLocationSubscription();
    this.riderMaxCapacity = this._common.getMaximumSharedRideSeats();
    this.pickupLocationMaxCapacity = this._sharedRideJourney.getSelectedSharedRideTypeDetails(this.sharedRideType).maxPickLocation;
    this.dropLocationMaxCapacity = this._sharedRideJourney.getSelectedSharedRideTypeDetails(this.sharedRideType).maxDropLocation;
    this.fetchPrevDetails();
  }


  setAdditionalDetails(){
    let prevBrr = this._sharedRideJourney.getBusinessRideReason();

    if(this.businessRideReasons != undefined){
      this.showOtherInputBox = this.businessRideReasons['type'] == 'TEXT';
      this.businessRideReasonInputDetails.label = this.businessRideReasons['type'] == 'TEXT' ? 'Business Ride Reason' : 'Manually add Reason';
      this.businessRideReasonInputDetails.required=this.businessRideReasons['mandatory'];
      this.businessRideReasonDropDownDetails.required=this.businessRideReasons['mandatory']; 
      let dropDownOptions = this.businessRideReasons['itemList'].map(item=>{return {label:item, value:item}});
      this.businessRideReasonDropDownDetails.options = dropDownOptions;
      if(this.businessRideReasons['otherRequired']){
        this.businessRideReasonDropDownDetails.options.push({label: 'Other', value: 'Other'})
      }
    }

    if(this.expenseCode != undefined){
      this.expenseCodeInputDetails.label = this.expenseCode['type'] == 'TEXT' ? 'Expense Code' : 'Manually add Expense Code';
      this.expenseCodeDropDownDetails.required = this.expenseCode['mandatory'];
      this.expenseCodeInputDetails.required = this.expenseCode['mandatory'];
      this.expenseCodeDropDownDetails.options = this.expenseCode && this.expenseCode['type'] == 'LIST' ? this.expenseCode['itemList'].map(item=>{return {label:item, value:item}}) : [];
    }

    if(prevBrr){
      if(this.businessRideReasons && this.businessRideReasons['type'] == 'TEXT'){
        this.businessRideReasonInputDetails.inputValue = prevBrr;
      }

      else{
        if(this.businessRideReasons && this.businessRideReasons['itemList'].includes(prevBrr)){
          this.businessRideReasonDropDownDetails.selectedValue = {label : prevBrr, value: prevBrr};
        }
        else{
          this.businessRideReasonDropDownDetails.selectedValue = {label : 'Other', value: 'Other'};
          this.businessRideReasonInputDetails.inputValue = prevBrr;
        }
      }
    }
  }

  onDropdownValueChange(type){
    this.showOtherInputBox = this.businessRideReasonDropDownDetails.selectedValue.value == 'Other';
    this._sharedRideJourney.setBusinessRideReason(this.businessRideReasonDropDownDetails.selectedValue.value == 'Other' ? '' : this.businessRideReasonDropDownDetails.selectedValue.value);
  }

  onBRRInput(){
    this._sharedRideJourney.setBusinessRideReason(this.businessRideReasonInputDetails.inputValue);
  }

  startShowAddLocationSubscription(){
    this.showAddLocationCtaSubscription = this._sharedRideSubscriptionService.fetchShowAddLocationCtaObserver().subscribe(res=>{
      this.isLocationCtaVisible();
    })
  }


  setActiveAcc(event, index){
    this.locationAccDetails.forEach(acc=>acc.isActive = false);
    this.locationAccDetails[index].isActive  = !this.locationAccDetails[index].isActive;
  }


  addLocation(){
    this.locationAccDetails.forEach(acc=>acc.isActive = false);
    
    if(this.locationAccCountArr.length < this.pickupLocationMaxCapacity){
      this.locationAccCountArr.push('location');
      this.locationAccDetails.push({
        label: `Pickup Location ${this.locationAccDetails.length+1}`,
        isActive: true,
        isComplete: false,
        isVisible: true,
        isDisabled: false,
        showDeleteIcon: true
      });

      console.log(this.locationAccCountArr, this.locationAccDetails);
      this.isLocationCtaVisible();
    }
    else{
      this.isLocationCtaVisible();
      return ;
    }


    this.showDeleteIcon();
    this.validateData();
  }


  showDeleteIcon(){
    let locationLength = this.locationAccDetails.length;
    if(locationLength == 1){
      this.locationAccDetails.forEach(location=>location.showDeleteIcon = false);
    }

    else{
      this.locationAccDetails.forEach(location=>location.showDeleteIcon = true);
    }

  }

  setSelectedTab(tabIndex){
      this.selectedTab = this.locationTabs[tabIndex].value;
      this.isLocationCtaVisible();
    
  }


  isLocationCtaVisible(){
    
    if(this._sharedRideCustomer.isRiderListFilled() || this.selectedTab == 'DROP'){
      this.showAddLocationCta = false;
    }
    else{
      this.showAddLocationCta = true;
    }
  }


  changeActiveStep(){
    this._sharedRide.setStopsList();
    let minRiderCapactiy = this._common.getMinimumSharedRideSeats();

    if(this.businessRideReasons['mandatory'] && this._sharedRideJourney.getBusinessRideReason() == ''){
      this._commonSubscription.emitAlert({
        theme: Theme.WARNING,
        title: 'Something Missing !',
        message: 'Please Add Business Ride Reason',
        ctaLabel: 'Ok'
      })

      return ;
    }
      
    else{
      if(this.selectedTab == 'PICKUP'){
        if(this.checkForMinimumRequirementsToCreateRide()){
          this.setSelectedTab(1);
          this.validateData();
          this._sharedRideJourney.setSharedRideLocationDetails(this.locationDetailsArr);
          this._common.scrollTop(30);
        }
        else{
          return;
        }
        
    }

    else if(this.selectedTab == 'DROP'){
      this.validateData();
      this._sharedRideJourney.setSharedRideLocationDto(this.locationDto)
      this.changeStep.emit(true);
      this._common.scrollTop(30);
    }
    }
  }


  startCloseDialogSubscription(){
    this.closeDialogSubscription = this._commonSubscription.fetchCloseAlert().subscribe(res=>{
      if(res[1] == Theme.CUSTOM){
        if(this.locationAccCountArr.length > 1){
          this.locationDetailsArr.splice(this.deleteIndex, 1);
          this.locationAccCountArr.splice(this.deleteIndex, 1);
          this.locationAccDetails.splice(this.deleteIndex, 1);
          this._sharedRideJourney.removeSharedRideLocation(this.deleteIndex);
    
          this.locationAccDetails.forEach((accDetails,index)=>{
            accDetails.label = `Pickup Location ${index + 1}`
          })
          this.isLocationCtaVisible();
        }
        else{
          return;
        }
        this.showDeleteIcon();
        this.validateData()
      }

      else{
        return;
      }
        
    })
  }


  throwAlert(index){
      this._commonSubscription.emitAlert({
        theme: Theme.CUSTOM,
        title: '',
        message: '',
        ctaLabel: 'Yes',
        cancelCta: true,
        cancelCtaLabel : 'No',
        template: `
        <div>
          <div style='margin-bottom:24px'>
          <img src='assets/delete-icon-popup.svg'>
          </div>

          <div style ='padding-left: 6px'>
          <p style='margin: 0; color: #555555; font-weight: 600; font-size: 18px;'>Are you sure that you want to delete this pickup ?</p>
          <p style='color: #959595; font-size: 16px; font-weight: 400; width:95%'>Deleting this pickup will lead to unassigned riders mapped with this pickup location</p>
          </div>

          <div style='background-color: #F1F6FA; padding: 4px 8px; border-radius: 12px; font-weight: 500; display:${this.locationDetailsArr[index] && this.locationDetailsArr[index]['locationName'] != null && this.locationDetailsArr[index]['locationName'] != '' ? 'flex' : 'none'}; gap:8px; align-items:flex-start;'>
            <img src='assets/pick.svg' style='margin-top: 4px;'>
            <span>${this.locationDetailsArr[index] ? this.locationDetailsArr[index]['locationName'] : ''}</span>
          </div>
        </div>
        `
      })
  }

  deleteLocation(event, index){
    this.deleteIndex = index;
    this.throwAlert(index);
  }


  fetchPrevDetails(){
    this.locationAccCountArr = [];
    this.locationAccDetails = [];
    this.riderDetailsArr = [];
    
    let stopsList = this._sharedRide.getStopsList();
  
    if( stopsList && Object.keys(stopsList).length > 0){
      this.fetchPrevStopsList(stopsList).then(res=>{
        this.validateData();
        this.isLocationCtaVisible();
      })
    }

    else{
      this.addLocation();
    }

    if(this._sharedRide.getRequestSharedRideDto().locationDto){
      this.locationDto = this._sharedRide.getRequestSharedRideDto().locationDto;
    }
  }

  fetchPrevStopsList(stopsList){
    let x  = new Promise((res, rej)=>{
      for(let i = 0; i < Object.keys(stopsList).length; i ++ ){
        this.riderDetailsArr[i] = [...this.riderDetailsArr, ...stopsList[i]['ridersList']];
        this.locationDetailsArr.push(stopsList[i]['locationDetails'])
        this.locationAccDetails ? this.locationAccDetails.forEach(acc=>acc.isActive = false) :  null;
        this.locationAccCountArr.push('location');
        this.locationAccDetails.push({
          label: `Pickup Location ${this.locationAccDetails.length+1}`,
          isActive: true,
          isComplete: false,
          isVisible: true,
          isDisabled: false,
          showDeleteIcon: true
        });
        this.isLocationCtaVisible();
      }
       res(this.locationAccCountArr);
    })

    return x;
  }


  deleteRider(riderIndex, locationIndex){
    this.riderDetailsArr[locationIndex].splice(riderIndex, 1);
    this.validateData();
  }


  getLocation(locationData, locationIndex){
    if(locationIndex == null){
      this.locationDto = locationData;   
      this.validateData();
     }
    else{
      this.locationDetailsArr[locationIndex] = locationData;   
      this.validateData();   
    }
  }


  getRiderDetails(riders, locationIndex){
  
      if(locationIndex != null){
        this.riderDetailsArr[locationIndex] = riders;
        this.validateData();
      }
  }

  validateData(){
    let validationArr = [];

    let isValidLocation = (location)=> location && location.lat !== 0 && location.lng !== 0 && location.locationName != '';
    let isValidRiders = (ridersList)=>ridersList && ridersList != null && ridersList.length > 0 ;

    if(this.selectedTab == 'PICKUP'){
      for(let i = 0; i < this.locationAccCountArr.length ; i++){
        validationArr[i] = null;
        validationArr[i] = isValidLocation(this.locationDetailsArr[i]) ? isValidRiders(this.riderDetailsArr[i] ? this.riderDetailsArr[i] : null) : false;
      }

      this.addLocationCtaDetails.disabled = validationArr.includes(false) || validationArr.includes(null) ? true : false;

      if(validationArr.includes(false) || validationArr.includes(null)){
        this.submitLocationCtaDetails.disabled = true
        this.locationTabs[1].isDisabled = true;
      }
      else{
        this.checkForMinimumRequirementsToCreateRide();
      } 
    }

    else{
      this.isLocationCtaVisible();
      validationArr.push(isValidLocation(this.locationDto));
      this.submitLocationCtaDetails.disabled = validationArr.includes(false);
    }

    return validationArr.includes(false);
  }

  checkForMinimumRequirementsToCreateRide(){
    if(this._sharedRideCustomer.getRiderListArrLength() < this._common.getMinimumSharedRideSeats()){
      this.submitLocationCtaDetails.disabled = true;
      this.locationTabs[1].isDisabled = true;
      return false;
    }

    else{
      this.submitLocationCtaDetails.disabled = false;
      this.locationTabs[1].isDisabled = false;
      return true;
    }
  }


  ngOnDestroy(): void {
    this.showAddLocationCtaSubscription.unsubscribe();
    this.closeDialogSubscription.unsubscribe();
  }

 

}
