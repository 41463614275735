<div class="main-container">
    <div class="time-stamp-container">
        <div class="date-day-container" [ngClass]="{'date-day-container-with-week': showDaysOfWeek}">
            <div class="date-caption">
                <span>Select Date</span>
            </div>

            <div class="date-container">
                <div class="start-date-container">
                    <custom-input [inputBoxDetails]="startDateInputDetails" [inputType]="'CALENDAR'" (onSelectDate)="setEndDate()" (onDatePanelVisible)="setLeadTime()" (onClearInput)="clearTimestamp()"></custom-input>
                </div>

                <div class="end-date-container">
                    <custom-input [inputBoxDetails]="endDateInputDetails" [inputType]="'CALENDAR'" (onSelectDate)="checkIfShowDaysOfWeek()" (onClearInput)="clearTimestamp()"></custom-input>
                </div>
            </div>

            <div class="days-of-week-container" *ngIf="showDaysOfWeek">
                <span class="days-of-week-caption">Days of week (Book ride on specific days)</span>
                
                <div class="days-of-week-data">
                    <div class="day-container" *ngFor="let day of daysOfWeek; index as i" (click)="toggleDaysOfWeek(i)">
                        <div [ngClass]="{'day-container-selected': daysOfWeekCheckBoxInput[i]['checked'], 'day-container-unselected' : !daysOfWeekCheckBoxInput[i]['checked']}">
                            
                            <div class="day-checkbox" (click)="$event.stopPropagation()">
                                <custom-checkbox [checkboxDetails]="daysOfWeekCheckBoxInput[i]"></custom-checkbox>
                            </div>
        
                            <span class="day">{{day | daysOfWeekShort}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="time-container">
            <span class="time-caption">Select Time</span>
            <custom-input [inputBoxDetails]="timeInputDetails" [inputType]="'CALENDAR'" (onDatePanelClose)="setStartTime()" (onDatePanelVisible)="setTime()"></custom-input>
        </div>

        <div class="ride-request-cta-container" *ngIf="showRideRequestCta">
            <custom-button [buttonDetails]="rideRequestCtaDetails" (onCustomButtonClick)="submitTimeStamp()"></custom-button>
        </div>

    </div>
</div>