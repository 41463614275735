import { Injectable } from '@angular/core';
import { SharedRideJourneyDetails, SharedRideLocationDetails, SharedRideType } from 'src/app/app-v2/types/container.type';
import { SharedRideContainerService } from './shared-ride-container.service';
import { CommonService } from '../../common.service';
import { SharedRideTypes } from 'src/app/app-v2/types/enums';
import { SharedRideCustomerDetailsService } from './shared-ride-customer-details.service';

@Injectable({
  providedIn: 'root'
})
export class SharedRideJourneyDetailsService {

  constructor(private _common: CommonService, private _customer: SharedRideCustomerDetailsService) { }

  sharedRideType: SharedRideType[] = [
    {
      url: "assets/shared-ride-pick.svg", 
      title: "Shared Pickup", 
      caption: "Multiple Pickups One Drop",
      code: "ONE_DROP_MULTIPLE_PICKUPS",
      maxPickLocation: this._common.getMaximumSharedRideSeats(),
      maxDropLocation: 1
    },
    {
      url: "assets/shared-ride-drop.svg", 
      title: "Shared Drop", 
      caption: "Multiple Drops One Pickup", 
      code: "ONE_PICKUP_MULTIPLE_DROPS",
      maxPickLocation: 1,
      maxDropLocation: this._common.getMaximumSharedRideSeats()
    }
  ]
  
 
  sharedRideJourneyAccDetails: SharedRideJourneyDetails = {
    locationDetails: {
      label: 'Add pickup and drop location for riders added',
      isActive: true,
      isComplete: false,
      isVisible: true,
      isDisabled: false
    },
    timestampDetails: {
      label: 'Select date and time',
      isActive: false,
      isComplete: false,
      isVisible: true,
      isDisabled: true
    }
  }

  selectedSharedRideType: string = SharedRideTypes.SHARED_PICKUP;

  sharedRideLocationDetails :any = {};
  sharedRideLocationDto: SharedRideLocationDetails = {
    lat: 0,
    lng: 0,
    locationName: null,
  };

  selectedZoneId:number = 0;
  selectedZoneIdForLeadTime = {id: 0, isChanged: false};
  selectedDaysOfWeek: string[] = [];
  startDate: number | null = null;
  endDate: number | null = null;
  businessRideReason = '';

 
  getSharedRideTypes(){
    return this.sharedRideType;
  }

  getSelectedSharedRideTypeDetails(rideType){
    let sharedRideTypeCodes = this.sharedRideType.map(rideType=>rideType.code);
    for(let i=0; i < this.sharedRideType.length; i++){
      if(this.sharedRideType[i].code == rideType){
        return this.sharedRideType[i];
      }
    }
  }

  setSelectedZoneId(zone){
    this.selectedZoneId = zone;
  }

  getSelectedZoneId(){
    return this.selectedZoneId
  }

  setBusinessRideReason(brr){
    this.businessRideReason = brr;
  }

  getBusinessRideReason(){
    return this.businessRideReason;
  }

  setSelectedZoneIdForLeadTime(){
    this.selectedZoneIdForLeadTime.isChanged = this.selectedZoneIdForLeadTime.id == this.selectedZoneId ? false : true; 
    this.selectedZoneIdForLeadTime.id = this.selectedZoneId;
  }

  getSelectedZoneIdForLeadTime(){
    return this.selectedZoneIdForLeadTime;
  }

  // selected Shared Ride Type
  setSelectedSharedRideType(type: string){
    this.selectedSharedRideType = type;
  }

  getSelectedSharedRideType(){
    return this.selectedSharedRideType;
  }

  getSharedRideJourneyAccDetails(){
    return this.sharedRideJourneyAccDetails;
  }

  getSharedRideLocationDetails(){
    return this.sharedRideLocationDetails;
  }

  setSharedRideLocationDetails(locationDetails){
    this.sharedRideLocationDetails = Object.assign({}, locationDetails);
  }


  addSharedRideLocation(details: SharedRideLocationDetails, locationIndex: number){
    this.sharedRideLocationDetails[locationIndex.toString()] = details;
  }


  removeSharedRideLocation(locationIndex: number){
    let newLocationData = [];
    Object.values(this.sharedRideLocationDetails).forEach((location,index)=>{
      index != locationIndex ?  newLocationData.push(location) : null;
    })
    
    this.sharedRideLocationDetails = {};
    
    newLocationData.forEach((location,index)=>this.sharedRideLocationDetails[`${index}`] = location);
    this._customer.removeAllRiderDetailsBasedOnLocationIndex(locationIndex);
  }

  getIndexBasedLocationDetails(index){
    console.log(this.sharedRideLocationDetails)
    return this.sharedRideLocationDetails[index.toString()];
  }

  getAllLocationDetails(){
    return this.sharedRideLocationDetails;
  }

  setSharedRideLocationDto(details: SharedRideLocationDetails){
    this.sharedRideLocationDto = Object.assign({}, details);
  }

  getSharedRideLocationDto(){
    return this.sharedRideLocationDto;
  }


  setEndDate(date){
    this.endDate = date;
  }

  getEndDate(){
    return this.endDate
  }

  setStartDate(date){
    this.startDate = date; 
  }

  getStartDate(){
    return this.startDate
  }

  setDaysOfWeek(selectedDays){
    this.selectedDaysOfWeek = selectedDays 
  }

  getDaysOfWeek(){
    return this.selectedDaysOfWeek;
  }


  clearData(){
    this.sharedRideType = [
      {
        url: "assets/shared-ride-pick.svg", 
        title: "Shared Pickup", 
        caption: "Multiple Pickups One Drop",
        code: "ONE_DROP_MULTIPLE_PICKUPS",
        maxPickLocation: this._common.getMaximumSharedRideSeats(),
        maxDropLocation: 1
      },
      {
        url: "assets/shared-ride-drop.svg", 
        title: "Shared Drop", 
        caption: "Multiple Drops One Pickup", 
        code: "ONE_PICKUP_MULTIPLE_DROPS",
        maxPickLocation: 1,
        maxDropLocation: this._common.getMaximumSharedRideSeats()
      }
    ]
    
   
    this.sharedRideJourneyAccDetails = {
      locationDetails: {
        label: 'Add pickup and drop location for riders added',
        isActive: true,
        isComplete: false,
        isVisible: true,
        isDisabled: false
      },
      timestampDetails: {
        label: 'Select date and time',
        isActive: false,
        isComplete: false,
        isVisible: true,
        isDisabled: true
      }
    }
  
    this.selectedSharedRideType = SharedRideTypes.SHARED_PICKUP;
    this.sharedRideLocationDetails = {};
    this.sharedRideLocationDto = {
      lat: 0,
      lng: 0,
      locationName: null,
    };
  
    this.selectedZoneId = 0;
    this.selectedZoneIdForLeadTime = {id: 0, isChanged: false};
    this.selectedDaysOfWeek = [];
    this.startDate = null;
    this.endDate = null;
    this.businessRideReason = '';
  }
}
