
<!-- STATIC ACCORDIAN -->
<div class="main-container" *ngIf="accordianType == 'STATIC'">
    <div class="header-container" (click)="toggleAccordian()">
        <div class="head-title-container">
        <img src="assets/complete-badge.svg" alt="" *ngIf="accordianDetails?.isComplete">
        <span class="acc-title" [ngClass]="{'title-bold': accordianDetails?.isActive, 'title-regular': !accordianDetails?.isActive}">{{accordianDetails?.label}}</span> 

        <div #headerTemplate class="custom-html-container" *ngIf="accordianDetails.isHTMLEnabled"></div>
        </div>

        <span class="acc-arrow">
            <img src="assets/arrow.svg" alt="">
        </span>
    </div>
    
    <div class="body-container" *ngIf="accordianDetails?.isActive">
        <div class="acc-title">
            <ng-content select="[accBody]"></ng-content>
        </div>
    </div>
</div>


<!-- EDITABLE ACCORDIAN -->
<div class="editable-main-container" *ngIf="accordianType == 'EDITABLE'" [ngClass]="{'active-accordian': accordianDetails?.isActive && highlight}">
    <div class="editable-header-container" (click)="toggleAccordian()">
        <div class="editable-head-title-container">
        <img src="assets/complete-badge.svg" alt="" *ngIf="accordianDetails?.isComplete">
        <span class="editable-acc-title" [ngClass]="{'title-bold': accordianDetails?.isActive, 'title-regular': !accordianDetails?.isActive}">{{accordianDetails?.label}}</span> 
        </div>

        <span class="editable-acc-arrow">
            <img src="assets/delete-icon.svg" alt="" (click)="deleteAccordian()" *ngIf="accordianDetails?.showDeleteIcon">
            <img src="assets/arrow.svg" alt="">
        </span>
    </div>
    
    <div class="editable-body-container" *ngIf="accordianDetails?.isActive">
        <div class="editable-acc-title">
            <ng-content select="[editAccBody]"></ng-content>
        </div>
    </div>
</div>


