import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BreadCrumbAction, CustomDropDownInputType, CustomInputType, StepperData } from '../../types/components.type';
import { CreateRideService } from '../../getter-setter/container/create-ride/create-ride-container.service';
import { RideType } from '../../types/container.type';

import { CreateRideSubscriptionService } from '../../subscriptions/create-ride/create-ride-subscription.service';
import { Subscription } from 'rxjs';
import { CreateRideApiService } from '../../api-services/create-ride/create-ride-api.service';
import { CustomerDetailsContainerService } from '../../getter-setter/container/create-ride/customer-details-container.service';
import { JourneyDetailsContainerService } from '../../getter-setter/container/create-ride/journey-details-container.service';
import { Router } from '@angular/router';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { PaymentDetailsContainerService } from '../../getter-setter/container/create-ride/payment-details-container.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreadCrumbType, CreateRideSteps, StepperAlign, Theme } from '../../types/enums';
import { CreateRideRequestApiDto } from '../../types/apis.type';
import { SelectRideTypeContainerService } from '../../getter-setter/select-ride-type-container.service';

@Component({
  selector: 'create-ride-container',
  templateUrl: './create-ride-container.component.html',
  styleUrls: ['./create-ride-container.component.scss']
})
export class CreateRideContainerComponent implements OnInit,OnDestroy{
  label = 'Create Ride';
  activeStepSubscription: Subscription;
  triggerTimstampSubscription: Subscription;
  

  selectedRideTypeData:RideType; 
  breadCrumbAction: BreadCrumbAction; // breadCrumb Initialise
  
  stepperAlignment = StepperAlign.VERTICAL; // stepper alignment
  createRideNavigationSteps:StepperData[]; // stepper value intitialise
  
  activeStepDetails = {title: "" , caption: ""} // selected step title and caption
  selectedActiveStep = {};



constructor(private _createRide: CreateRideService,
            private _createRideApi: CreateRideApiService,
            private _commonSubscription: CommonSubscriptionService,
            private _subscription: CreateRideSubscriptionService,
            private _selectRideType: SelectRideTypeContainerService,
            private _payment: PaymentDetailsContainerService,
            private _loader: NgxSpinnerService,
            private _router: Router
            ){}


  ngOnInit(): void {
    this.setBreadCrumbLabel(BreadCrumbType.ADD, this.label);
    this.setSelectedRideType();
    this.startTimestampSubscription();
    this.startActiveStepSubscription();
  }


  setSelectedRideType(){
        this.selectedRideTypeData = this._selectRideType.getSelectedRideTypeDetails();
        this.setBreadCrumbLabel(BreadCrumbType.ADD, this.selectedRideTypeData.name);
        this.createRideNavigationSteps = this._createRide.getCreateRideNavigationSteps(this.selectedRideTypeData); // recieving no. of steps to create ride 
        this.toggleEnablingActiveStep(CreateRideSteps.CUSTOMER_DETAILS, true);
        this.setActiveStep(CreateRideSteps.CUSTOMER_DETAILS)
  }

  startTimestampSubscription(){
    this.triggerTimstampSubscription = this._subscription.fetchTriggerTimestampCtaObserver().subscribe(res=>{
     this._createRide.setCreateRideRequestApiDto().then((res)=>{
      this.sendRideRequest();
     });
    })
  }

 sendRideRequest(){
    this._loader.show();
    let requestDto = Object.assign({}, this._createRide.getCreateRideRequestApiDto());
    // check express ride
    requestDto['rideType'] = this._createRide.getIsExpressRide() ? 'LIVE_RIDE' : requestDto['rideType'];

    this._createRideApi.requestRide(requestDto).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        this._payment.setRideRequestDetails(res['response']);
        this.toggleEnablingActiveStep(CreateRideSteps.PAYMENT_DETAILS, true);
        this.setActiveStep(CreateRideSteps.PAYMENT_DETAILS);
      }
      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'OK!'
        })
      }
    })
  }

  startActiveStepSubscription(){
    this.activeStepSubscription = this._subscription.fetchCreateRideActiveStepObserver().subscribe(res=>{
      this.toggleEnablingActiveStep(res, true);
      this.setActiveStep(res);
    })
  }
  

  toggleEnablingActiveStep(currentStep, val){
    this.createRideNavigationSteps.forEach((step,index)=>{
      if(step.label == currentStep)
        {
        step.isDisabled = !val;
        }
      else
      {
        step.isDisabled = step.isDisabled;
      }
    })
  }

 
  setActiveStep(currentStep){
    let currentStepIndex:number = null;
    if(currentStep == CreateRideSteps.JOURNEY_DETAILS || currentStep == CreateRideSteps.WEEK_SCHEDULE){
      this.createRideNavigationSteps.forEach((step,index)=>{
        if(step.label == 'Payment Details'){
          step.isDisabled = true;
        }
        else{
          step.isDisabled = step.isDisabled;        }
      })  
    }
    // loop to switch active step
    this.createRideNavigationSteps.forEach((step,index)=>{
      if(step.label == currentStep && !step.isDisabled){
        currentStepIndex = index;
        step.isActive = true;
        this.activeStepDetails = {title: step.label, caption: step.caption}
        this.selectedActiveStep = step;
      }
      else{
        step.isActive = false;
      }
    });


    // loop to upgrade breadcrumb
    this.createRideNavigationSteps.forEach((step,index)=>{
      if(index < currentStepIndex){
        this.setBreadCrumbLabel(BreadCrumbType.ADD, step.label)
        step.isComplete = true
      }

      else if(index == currentStepIndex){
        this.setBreadCrumbLabel(BreadCrumbType.ADD, step.label)
      }

      else{
        step.isComplete = step.isComplete;
      }});
    }


    // when click on breadcrumb
    getScreenLabel(data){
    if(data == this.selectedRideTypeData.name){
      return;
    }
    else if(data == this.label){
      this._router.navigate(["/select"]);
      return;
    }
    else{
      this.setActiveStep(data);
      return;
    }
    
    }

    


    // Adding and Removing breadcrumb Label generic function;
    setBreadCrumbLabel(type, item){
    setTimeout(()=>{
      this.breadCrumbAction = { type: type ,item: item};
    },100);
    }

    
    ngOnDestroy(): void {
    this.activeStepSubscription.unsubscribe();
    this.triggerTimstampSubscription.unsubscribe(); 
    this.setBreadCrumbLabel(BreadCrumbType.REMOVE, this.label);
  }
}
