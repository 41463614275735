<div class="main-container">
    <div class="shared-ride-details-container">
        
        <div class="shared-ride-type-container" >
            <div class="shared-ride-types" *ngFor="let type of sharedRideTypes; index as i" (click)="selectSharedRideType(type.code)">
                <card type="container"  [isActive]="selectedRideType == type?.code">
                    <div class="shared-ride-type" container>
                        <img [src]="type?.url" alt="">

                        <div class="type-text-container">
                            <span class="type-title">{{type?.title}}</span>
                            <span class="type-caption">{{type?.caption}}</span>
                        </div>
                    </div>
                </card>
            </div>
        </div>

        <div class="accordian-container">
            <accordian [accordianDetails]="sharedRideJourneyAccDetails.locationDetails" *ngIf="sharedRideJourneyAccDetails.locationDetails.isVisible" accordianType = 'STATIC' (isActiveEvent)="toggleActiveAcc('locationDetails')">
                    <shared-pickup-container accBody *ngIf="selectedRideType == 'ONE_DROP_MULTIPLE_PICKUPS'" (changeStep)="changeActiveAcc($event)" [businessRideReasons]="businessRideReasonDetails" [expenseCode]="expenseCode"></shared-pickup-container>
                    <shared-drop-container accBody *ngIf="selectedRideType == 'ONE_PICKUP_MULTIPLE_DROPS'" (changeStep)="changeActiveAcc($event)" [businessRideReasons]="businessRideReasonDetails" [expenseCode]="expenseCode"></shared-drop-container>
            </accordian>



            <accordian [accordianDetails]="sharedRideJourneyAccDetails.timestampDetails" *ngIf="sharedRideJourneyAccDetails.timestampDetails.isVisible" accordianType = 'STATIC' (isActiveEvent)="toggleActiveAcc('timestampDetails')">                
                    <shared-ride-timestamp-details-container accBody *ngIf="sharedRideJourneyAccDetails?.timestampDetails?.isActive"></shared-ride-timestamp-details-container>
            </accordian>
        </div>



    </div>
</div>