<app-create-new-ride *ngIf="!showSharedRideNew"></app-create-new-ride>

<div class="main-container" *ngIf="showSharedRideNew">
    
    <div class="create-ride-container">
     <create-shared-ride-v2-container></create-shared-ride-v2-container>
     
    </div> 
 
 </div>

<pop-up></pop-up>