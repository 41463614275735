import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TabDetails } from '../../types/components.type';

@Component({
  selector: 'tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnChanges{

@Input() tabDetails: TabDetails[];
@Input() align: string;
@Input() selectedTab: string;
@Output() onTabSelected = new EventEmitter<number>();

ngOnChanges(changes: SimpleChanges): void {
  this.onSelectTab(this.getSelectedTabIndex(this.selectedTab));
}


getSelectedTabIndex(tabValue){
let tempArr = this.tabDetails.map(tab=>tab.value);
return tempArr.indexOf(this.selectedTab);
}

onSelectTab(index){
  if(!this.tabDetails[index].isDisabled){
    this.clearSelection();
    this.tabDetails[index].isSelected = true;
    this.onTabSelected.emit(index);
  }
  
}


clearSelection(){
  this.tabDetails.forEach(tab=>{
    tab.isSelected = false;
  })
}
}
