import { Component, OnInit } from '@angular/core';
import { SharedRideJourneyDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { SharedRideJourneyDetails, SharedRideType } from 'src/app/app-v2/types/container.type';
import { SharedRideTypes, Theme } from '../../types/enums';
import { SharedRideCustomerDetailsService } from '../../getter-setter/container/shared-ride/shared-ride-customer-details.service';
import { JourneyDetailsApiService } from '../../api-services/create-ride/journey-details-api.service';
import { CommonSubscriptionService } from '../../subscriptions/common/common-subscription.service';
import { SharedRideContainerService } from '../../getter-setter/container/shared-ride/shared-ride-container.service';

@Component({
  selector: 'shared-ride-journey-details-container',
  templateUrl: './shared-ride-journey-details-container.component.html',
  styleUrls: ['./shared-ride-journey-details-container.component.scss']
})
export class SharedRideJourneyDetailsContainerComponent implements OnInit{
  sharedRideTypes: SharedRideType[];
  selectedRideType: string;
  sharedRideJourneyAccDetails : SharedRideJourneyDetails;
  businessRideReasonDetails;
  expenseCode;
  
  constructor(private _sharedRideJourney: SharedRideJourneyDetailsService,
              private _sharedRideJourneyApi: JourneyDetailsApiService,
              private _sharedRideCustomer: SharedRideCustomerDetailsService,
              private _commonSubscription: CommonSubscriptionService,
              private _sharedRide: SharedRideContainerService
  ){}

  ngOnInit(): void {
    this.sharedRideTypes = this._sharedRideJourney.getSharedRideTypes();
    this.selectedRideType = this._sharedRideJourney.getSelectedSharedRideType()
    this.setSharedRideAccDetails().then(res=>{
      if(this._sharedRide.getIsEdit()){
        if(this._sharedRide.getEditContainer() == 'TIME'){
          this.sharedRideJourneyAccDetails.locationDetails.isActive = false;
          this.sharedRideJourneyAccDetails.timestampDetails.isActive = true;
        }

        else if(this._sharedRide.getEditContainer() == 'LOCATION'){
          this.sharedRideJourneyAccDetails.locationDetails.isActive = true;
          this.sharedRideJourneyAccDetails.timestampDetails.isActive = false;
          this.sharedRideJourneyAccDetails.timestampDetails.isDisabled = true;
        }


      }
    })
    this.fetchAdditionalDetails(); 
  }

  toggleActiveAcc(accName){
    if(!this.sharedRideJourneyAccDetails[accName]['isDisabled']){
      Object.keys(this.sharedRideJourneyAccDetails).forEach(acc=>this.sharedRideJourneyAccDetails[acc]['isActive'] = false);
      this.sharedRideJourneyAccDetails[accName]['isActive'] = !this.sharedRideJourneyAccDetails[accName]['isActive'];
    }
  }

  changeActiveAcc(event){
    this.sharedRideJourneyAccDetails.locationDetails.isActive = false;
    this.sharedRideJourneyAccDetails.locationDetails.isComplete = true;
    this.sharedRideJourneyAccDetails.timestampDetails.isActive = true;
    this.sharedRideJourneyAccDetails.timestampDetails.isDisabled = false;
  }

  selectSharedRideType(type: string){
    this.selectedRideType = type;
    this._sharedRide.setIsEdit(false);
    this._sharedRide.clearData();
    this._sharedRide.setEditContainer('');
    this._sharedRideJourney.clearData();
    this._sharedRideJourney.setSelectedSharedRideType(type);
    this._sharedRideCustomer.clearCustomerDetails();
    this.setSharedRideAccDetails();

  }

  fetchAdditionalDetails(){
    this._sharedRideJourneyApi.fetchReasonAndCode().subscribe(res=>{
      if(res['statusCode'] == 200){
        this.businessRideReasonDetails = res['response']['businessRideReasonDetails'];
        this.expenseCode = res['response']['expenseCodeDetails'];
      }

      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error',
          message: res['message'],
          ctaLabel: 'OK'
        })
      }
    })
  }

  setSharedRideAccDetails(){
    let x = new Promise((resolve, reject)=>{
      this.sharedRideJourneyAccDetails = Object.assign({}, this._sharedRideJourney.getSharedRideJourneyAccDetails());
      resolve(true);
    });

    return x;
  }
}
