<div class="main-container">
    <div class="faq-container" *ngFor="let faq of faqList; index as i">
        <div class="faq-accordian">
            <accordian [accordianDetails]="faqAccList[i]" accordianType = 'STATIC'>
                <div class="faq-content" accBody >
                    <span>{{faq.text}}</span>
                </div>
            </accordian>
        </div>
    </div>
</div>
