import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateSharedRideSubscriptionService {
  constructor() { }


  showAddLocationCta = new Subject<boolean>();
  requestRide = new Subject<boolean>();
  createRideActiveStep = new Subject<string>(); // 


  // Create Ride Steps Subscription
  emitCreateSharedRideActiveStepObserver(nextStep){
    this.createRideActiveStep.next(nextStep);
  }

  fetchCreateSharedRideActiveStepObserver(){
   return this.createRideActiveStep.asObservable();
  }


  //show Add Location Cta Subscription
  emitShowAddLocationCtaObserver(moveNext){
    this.showAddLocationCta.next(moveNext);
  }

  fetchShowAddLocationCtaObserver(){
    return this.showAddLocationCta.asObservable();
  }

  //request ride
  emitRequestRide(request){
    this.requestRide.next(request);
  }

  fetchRequestRide(){
    return this.requestRide.asObservable();
  }

}
