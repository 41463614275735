<div class="main-container">

    <div class="caption-container">
        <img src="assets/shared-ride-add-rider.svg" alt="">
        <span class="caption-title">Add Rider</span>
        <span class="caption-text">You can add up-to {{maxRiderCount}} riders only</span>
    </div>
    
    <div class="rider-details-container">
        <span class="rider-title">Rider {{riderIndex}}</span>
        
        <div class="phone-container">
            <custom-input [inputType]="'PHONE'" [inputBoxDetails]="customerDetails.phoneNumber" (onSendInputData) = "getInfoFromInputThroughApi($event)"></custom-input>
        </div>
        

        <div class="name-container">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.firstName"></custom-input>
        </div>
        

        <div class="name-container">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.lastName"></custom-input>
        </div>
        
        <div class="email-container">
            <custom-input [inputType]="'BASIC'" [inputBoxDetails]="customerDetails.email"></custom-input>
        </div>


        <div class="notes-container">
            <custom-text-area [textAreaDetails]="pickupNotesDetails"></custom-text-area>   
        </div>

    </div>

    <div class="add-rider-cta-container">

        <div class="cta-container">
            <custom-button [buttonDetails]="cancelCta" (onCustomButtonClick)= "closeDialog()"></custom-button>
        </div>
        
        <div class="cta-container">
            <custom-button [buttonDetails]="addRiderCta" (onCustomButtonClick) ="addRiderDetails()"></custom-button>
        </div>
        
    </div>
</div>