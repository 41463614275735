import { Component, OnInit } from '@angular/core';
import { Button } from 'primeng/button';
import { min, single } from 'rxjs';
import { CommonService } from 'src/app/app-v2/getter-setter/common.service';
import { JourneyDetailsContainerService } from 'src/app/app-v2/getter-setter/container/create-ride/journey-details-container.service';
import { SharedRideJourneyDetailsService } from 'src/app/app-v2/getter-setter/container/shared-ride/shared-ride-journey-details.service';
import { CreateSharedRideSubscriptionService } from 'src/app/app-v2/subscriptions/create-ride/create-shared-ride-subscription.service';
import { CalendarType, checkboxType, CustomButton } from 'src/app/app-v2/types/components.type';
import { ButtonTypes, DaysOfWeek } from 'src/app/app-v2/types/enums';

@Component({
  selector: 'shared-ride-timestamp-details-container',
  templateUrl: './shared-ride-timestamp-details-container.component.html',
  styleUrls: ['./shared-ride-timestamp-details-container.component.scss']
})
export class SharedRideTimestampDetailsContainerComponent implements OnInit {

  startDateInputDetails : CalendarType = {
    placeholder: 'Select Date',
    inputValue: null,
    label: 'Start Date',
    name: 'startDate',
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    isDisabled: false,
    format: 'dd/mm/yy',
    required: true,
    enableTooltip: false,
    tooltipContent: '',
    minDate: new Date(),
    maxDate: this.setMaxDate(),
    selection: 'single',
    showIcon: true,
    iconUrl: 'assets/calendar-date.svg'
  }

  endDateInputDetails : CalendarType = {
    placeholder: 'Select Date',
    inputValue: null,
    label: 'End Date',
    name: 'endDate',
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    isDisabled: true,
    format: 'dd/mm/yy',
    required: true,
    enableTooltip: false,
    tooltipContent: '',
    minDate: new Date(),
    maxDate: this.setMaxDate(),
    selection: 'single',
    showIcon: true,
    iconUrl: 'assets/calendar-date.svg'
  }

  timeInputDetails : CalendarType = {
    placeholder: 'Select Time',
    inputValue: null,
    label: 'Time',
    name: 'time',
    validation: {
      isError: false,
      isSuccess: false,
      message: ''
    },
    isDisabled: true,
    format: '',
    timeFormat: 12,
    required: false,
    enableTooltip: false,
    tooltipContent: '',
    showTime: true,
    timeOnly : true,
    selection: 'single',
    minDate: new Date(),
    maxDate: null,
    stepHour : 1,
    stepMinute : this._common.getIsAdminUser() ? 1 : 15,
    showIcon: true,
    iconUrl: 'assets/calendar-time.svg'
  }

  rideRequestCtaDetails: CustomButton = {
    label: 'Next',
    left: false,
    right: false,
    leftUrl: '',
    rightUrl: '',
    disabled: false,
    type: ButtonTypes.FILLED
  }

  daysOfWeek = ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"];
  daysOfWeekCheckBoxInput: checkboxType[] = [];
  showDaysOfWeek = false;
  showRideRequestCta = false;

  constructor(private _common: CommonService, 
              private _journey: SharedRideJourneyDetailsService,
              private _sharedRideSubscription: CreateSharedRideSubscriptionService){}


  ngOnInit(): void {
    this.setDaysOfWeek();
    this._common.setMaxCreateRideDate();
    
    this.startDateInputDetails.maxDate = this.setMaxDate();
    this.endDateInputDetails.maxDate = this.setMaxDate();
    
    this._journey.setSelectedZoneIdForLeadTime();
    this._common.setLeadTimes(this._journey.getSelectedZoneIdForLeadTime())

    this.fetchPrevDetails();
    this.endDateInputDetails.inputValue = this._journey.getEndDate() ? new Date(this._journey.getEndDate()) : null; 
  }

  setEndDate(){
    let newEndDate = new Date();
    newEndDate.setDate(this.startDateInputDetails.inputValue.getDate() +  1);
    this.endDateInputDetails.minDate = newEndDate;
    newEndDate.setHours(0,0,0,0);
    this.endDateInputDetails.inputValue = newEndDate;
    this.endDateInputDetails.isDisabled = false;

    this.timeInputDetails.maxDate = this.startDateInputDetails.maxDate;
    this.timeInputDetails.minDate = this.handleLeadTime();
    this.timeInputDetails.isDisabled = false;

    this.setTime();
    this.showRideRequestCta = true;

    this.checkIfShowDaysOfWeek();
  }

  setStartTime(){
    if(this.timeInputDetails.inputValue){
      this.startDateInputDetails.inputValue.setHours(this.timeInputDetails.inputValue.getHours());
      this.startDateInputDetails.inputValue.setMinutes(this.timeInputDetails.inputValue.getMinutes());
  
      this.showRideRequestCta = true;
    }
  }

  setLeadTime(){
    this.startDateInputDetails.inputValue = this.handleLeadTime();
    this.startDateInputDetails.minDate = this.startDateInputDetails.inputValue;
    this.timeInputDetails.inputValue = this.startDateInputDetails.inputValue
    this.timeInputDetails.minDate = this.startDateInputDetails.inputValue;
  }

  setTime(){
    this.timeInputDetails.inputValue =  this.timeInputDetails.inputValue || this.startDateInputDetails.inputValue;
    this.timeInputDetails.minDate = this.handleLeadTime();
  }

  checkIfShowDaysOfWeek(){

    if(this.startDateInputDetails.inputValue){
      let startDate =  this.startDateInputDetails.inputValue.getDate();
      let endDate = this.endDateInputDetails.inputValue.getDate();
      let diff = this.endDateInputDetails.inputValue.getTime() -  this.startDateInputDetails.inputValue.getTime()
      console.log((diff/(1000 * 60 * 60 * 24)) >= 1, (diff/(1000 * 60 * 60 * 24)), diff)
      this.showDaysOfWeek = (diff/(1000 * 60 * 60 * 24)) > 1;
      this.setDefaultDaysOfWeek(diff/(1000 * 60 * 60 * 24));
    }
   
    else{
      return '';
    }

    console.log(this.showDaysOfWeek); 
  }

  setDefaultDaysOfWeek(diff){
    let selectedDaysOfWeek = this._journey.getDaysOfWeek();
    
      let startDay = this.startDateInputDetails.inputValue.getDay();
      let endDay = this.endDateInputDetails.inputValue.getDay();

      if(diff >= 6){
        this.daysOfWeekCheckBoxInput.forEach(checkBox=>checkBox.checked = true);
      }
      
      else if(diff <= 1){
        return;
      }

      else{
          if(startDay > endDay){
            for(let i= startDay; i < this.daysOfWeekCheckBoxInput.length; i++){
              this.daysOfWeekCheckBoxInput[i].checked = true;
            }

            for(let j=0; j< startDay; j++){
              this.daysOfWeekCheckBoxInput[j].checked = j <= endDay;
            }
          }

          else{
            this.daysOfWeekCheckBoxInput.forEach((checkBox,index)=>{
              return checkBox.checked = index >= startDay && index <= endDay;
            });
          }
      }
  }

  handleLeadTime(){
    let startDate = new Date();
    let leadTime = 45 //this.getLeadTime();
    startDate.setMinutes(startDate.getMinutes() + leadTime);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    if(this._common.getIsAdminUser() || this._common.getBusinessSpecEligibleForAdmin()){
      return startDate;
    }

    else{
      let extraBuffer = this.getExtraBufferTime(startDate.getMinutes());
      extraBuffer == 0 ? startDate.setHours(startDate.getHours() + 1) : startDate.setHours(startDate.getHours());
      startDate.setMinutes(extraBuffer);
      return startDate;
    }    
  }

  getLeadTime(){
    if(this._common.getIsAdminUser()){
      return 0;
    }
    else {
      return this._common.getLeadTime();
    }
  }


  getExtraBufferTime(minVal){
    let buffer = 0;
    for(let i = 0; i < 60; i=i+this.timeInputDetails.stepMinute){
      if(minVal >= i && minVal < i + this.timeInputDetails.stepMinute ){  
        buffer = i + this.timeInputDetails.stepMinute;
      }
      else{
        buffer = buffer;
      }
    }
    return buffer;
  }

  setDaysOfWeek(){
    this.daysOfWeekCheckBoxInput = this.daysOfWeek.map(day=>{
      return {checked: false, disabled: false};
    })
  }

  toggleDaysOfWeek(index){
      let startDayIndex = this.startDateInputDetails.inputValue.getDay();
      let endDayIndex = this.endDateInputDetails.inputValue.getDay();

      this.daysOfWeekCheckBoxInput.forEach((checkbox,index)=>{
        checkbox.disabled = !(index <= endDayIndex && index >= startDayIndex);
      })

      console.log(this.daysOfWeekCheckBoxInput);
      if(index <= endDayIndex && index >= startDayIndex){
          this.daysOfWeekCheckBoxInput[index].checked = !this.daysOfWeekCheckBoxInput[index].checked
        }
  }

  setMaxDate(date = null){
    let maxDate = date ? new Date(date) : new Date();
      //changed the addition of days from 3 to 30 as per ThomasCook requirements
      maxDate.setDate(maxDate.getDate() + this._common.getMaxCreateRideDate());
      maxDate.setHours(23, 0, 0, 0);

      return maxDate;
  }

  clearTimestamp(){
    this.startDateInputDetails.inputValue = null;
    this.endDateInputDetails.inputValue = null;
    this.endDateInputDetails.isDisabled = true;
    this.timeInputDetails.inputValue = null;
    this.timeInputDetails.isDisabled = true;
    this.showDaysOfWeek = false;
    this.showRideRequestCta = false;
    this.daysOfWeekCheckBoxInput.forEach(days=>days.checked = false);
  }

  submitTimeStamp(){
    this._journey.setEndDate(this.endDateInputDetails.inputValue.getTime());
    this._journey.setStartDate(this.startDateInputDetails.inputValue.getTime());
    let selectedDaysOfWeek = [];
    this.daysOfWeekCheckBoxInput.forEach((days,index)=>days.checked ? selectedDaysOfWeek.push(this.daysOfWeek[index]) : null);
    this._journey.setDaysOfWeek(selectedDaysOfWeek);

    this._sharedRideSubscription.emitRequestRide(true);
  }


  fetchPrevDetails(){
    this.startDateInputDetails.inputValue = this._journey.getStartDate() == null ?  this.startDateInputDetails.inputValue : new Date(this._journey.getStartDate())

    this.endDateInputDetails.inputValue = this._journey.getEndDate() == null ?  this.endDateInputDetails.inputValue : new Date(this._journey.getEndDate())
    this.endDateInputDetails.isDisabled = this._journey.getEndDate() == null ? true : false;

    this.timeInputDetails.inputValue = this._journey.getStartDate() == null ? this.timeInputDetails.inputValue : new Date(this.startDateInputDetails.inputValue);
    this.timeInputDetails.isDisabled = this._journey.getStartDate() == null ? true : false;
    
    this.timeInputDetails.minDate = this.handleLeadTime();

    this.showRideRequestCta = this._journey.getStartDate() == null ? false : true;
    this.checkIfShowDaysOfWeek();
  }
  
}
