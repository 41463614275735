<div class="main-container">
    <accordian [accordianDetails]="journeyDetails.locationDetails" *ngIf="journeyDetails.locationDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event, 'locationDetails')" accordianType = 'STATIC'>
        <location-container accBody *ngIf="journeyDetails.locationDetails.isActive"></location-container>
    </accordian>

    <accordian [accordianDetails]="journeyDetails.carVariantDetails" *ngIf="journeyDetails.carVariantDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event,'carVariantDetails')" accordianType = 'STATIC'>
        <car-variant-container accBody (prevAccEmiiter)="setActiveAccordian($event)" *ngIf="journeyDetails.carVariantDetails.isActive"></car-variant-container>
    </accordian>

    <accordian [accordianDetails]="journeyDetails.timestampDetails" [HTMLContent]="expressRideBanner" accordianType = 'STATIC'
    *ngIf="journeyDetails.timestampDetails.isVisible" (isActiveEvent)="toggleActiveAcc($event,'timestampDetails')">
        <timestamp-container accBody *ngIf="journeyDetails.timestampDetails.isActive"></timestamp-container>
    </accordian>
</div>

