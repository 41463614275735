<div class="main-container">

    <div class="tab-container">
        <tab [tabDetails]="locationTabs" align="HORIZONTAL" [selectedTab]="selectedTab" (onTabSelected)="setSelectedTab($event)"></tab>
    </div>

    <div class="location-acc-container">

        <div *ngIf="selectedTab == 'PICKUP'" class="location-acc-container" (click)="isLocationCtaVisible()">
            <div class="acc-container">
                <accordian [accordianDetails]="pickupLocationAccDetails" [accordianType]="'EDITABLE'" [highlight]="true">
                    <div class="add-location-container" editAccBody>
                        <shared-ride-add-location-container [enableAddRiderCta] = "false" [locationIndex]="null" (locationData)="getLocation($event, null)"
                        locationType="PICK" [showRiderActionCta]="false"></shared-ride-add-location-container>
                    </div>
                </accordian>
            </div>
        </div>


        <div *ngIf="selectedTab == 'DROP'" class="location-acc-container" (click)="isLocationCtaVisible()">
            <div class="acc-container" *ngFor="let location of locationAccCountArr; index as i">
                <accordian [accordianDetails]="locationAccDetails[i]" [accordianType]="'EDITABLE'" (deleteAcc)="deleteLocation($event, i)" (isActiveEvent)="setActiveAcc($event, i)" [highlight]="true">
                    <div class="add-location-container" editAccBody *ngIf="locationAccDetails[i]?.isActive">
                        <shared-ride-add-location-container [enableAddRiderCta] = "true" [locationIndex]="i" (locationData)="getLocation($event, i)"
                        locationType="DROP" [showRiderActionCta]="true" (riderDetails)="getRiderDetails($event, i)" (deleteRider)="deleteRider($event, i)"></shared-ride-add-location-container>
                    </div>
                </accordian>
            </div>
        </div>


        <div class="location-cta-container">            
            <div class="location-cta">
                <custom-button [buttonDetails]="submitLocationCtaDetails" (onCustomButtonClick)="changeActiveStep()"></custom-button>
            </div>
            
            <div class="location-cta" *ngIf="showAddLocationCta">
                <custom-button [buttonDetails]="addLocationCtaDetails" (onCustomButtonClick)="addLocation()"></custom-button>
            </div>
        </div>

           <!-- Additional Details  -->
           <accordian [accordianType]="'EDITABLE'" [accordianDetails]="additionalDetailsAcc" [highlight]="false">
            <div class="additional-details-container" editAccBody>
                    <div class="additional-details-container">
                    
                    <!-- Business Ride Reason -->
                    <div class="business-ride-reason-container">
                        <div class="image-container" *ngIf="businessRideReasons?.['enabled']">
                            <img src="assets/brrAndExpense.svg" alt="">
                        </div>
                
                        <div class="input-container">
                    <dropdown [dropdownDetails]="businessRideReasonDropDownDetails" *ngIf="businessRideReasons?.['type'] == 'LIST'" (changehandler)="onDropdownValueChange('REASON')"></dropdown>
                    <custom-input inputType="BASIC" [inputBoxDetails]="businessRideReasonInputDetails" *ngIf="showOtherInputBox" (onSendInputData)="onBRRInput()"></custom-input>
                        </div>
                    </div>
            
            
                    <!-- Expense Code -->
                    <!-- <div class="expense-code-container" *ngIf="expenseCode?.['enabled']">
            
                        <div class="image-container" *ngIf="expenseCode?.enabled">
                            <img src="assets/expense_code.svg" alt="">
                        </div>
                
                        <div class="input-container">
                    <dropdown [dropdownDetails]="expenseCodeDropDownDetails" *ngIf="expenseCode?.type == 'LIST'" (changehandler)="onDropdownValueChange('CODE')"></dropdown>
                    <custom-input inputType="BASIC" [inputBoxDetails]="expenseCodeInputDetails" *ngIf="expenseCode?.type != 'LIST'"></custom-input>
                    </div>
                    </div> -->
                </div>
            </div>
        </accordian>


      
    </div>
</div>