import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_URL_GET_COUNTRY_CODES, API_URL_LEAD_TIME } from 'src/app/config/apiRouteConfig';
import { CarVariantType, RideType } from '../types/container.type';
import { CommonSubscriptionService } from '../subscriptions/common/common-subscription.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CountryFeatureListService } from '../localization/country-feature-list/country-feature-list.service';
import { JourneyDetailsContainerService } from './container/create-ride/journey-details-container.service';
import { Theme } from '../types/enums';


@Injectable({
  providedIn: 'root'
})
export class CommonService {
  allowedRideTypesDetails : RideType[] = [];
  allowedRideTypes : string[];
  showSharedRideNew :boolean = false;
  
  callingCodeList = [];
  allCarVariants:CarVariantType[];
  selectedCountry: string;
  defaultAgmCoordinates:{lat: number, lng: number};
  leadTimes = {};
  maxCreateRideDate : number;

  superAdminList = ["346191","158699","1626","2526","1545058","62658","698957"];
  BusinessSpecsEligibleForAdmin = ['HOTEL'];

  isAdminUser = false;
  businessSpec = "";
  maximumSeats = 4;
  minimumSeats = 2;

  constructor(private _http: HttpClient, 
              private _commonSubscription: CommonSubscriptionService,
              private _loader: NgxSpinnerService,
              private _journey: JourneyDetailsContainerService,
              private _countryFeature: CountryFeatureListService) { }

  setIsShowSharedRideNew(isNewSharedRide){
    this.showSharedRideNew = isNewSharedRide;
  }

  getIsShowSharedRideNew(){
    return this.showSharedRideNew;
  }

  setMaximumSharedRideSeats(seats){
    this.maximumSeats = seats;
  }

  getMaximumSharedRideSeats(){
    return this.maximumSeats;
  }
  
  setMinimumSharedRideSeats(seats){
    this.minimumSeats = seats;
  }

  getMinimumSharedRideSeats(){
    return this.minimumSeats;
  }

  setSelectedCountry(country){
    this.selectedCountry = country;
  }              

  getSelectedCountry(){
    return this.selectedCountry;
  }

  setBusinessSpec(spec, location=null){
    this.businessSpec = spec
  }

  getBusinessSpec(){
    return this.businessSpec;
  }

  getBusinessSpecEligibleForAdmin(){
    return this.BusinessSpecsEligibleForAdmin.includes(this.businessSpec) ? true : false;
  }

  setMaxCreateRideDate(){
    this.maxCreateRideDate = this._countryFeature.getCountryFeatureList()['maxCreateRideTimeStamp'];
  }

  getMaxCreateRideDate(){
    return this.maxCreateRideDate;
  }

  setLeadTimes(zone = null){
    let zoneId = zone ? zone : this._journey.getSelectedZoneIdForLeadTime();

    let x = new Promise((resolve, reject)=>{
      if(zoneId.isChanged){
        this._loader.show();
        this._http.get<any>(`${API_URL_LEAD_TIME}?zoneId=${zoneId.id}`).subscribe(res=>{
          this._loader.hide();
          if(res['statusCode'] == 200){
            this.leadTimes = res['response'];
            if(!res['response']['a2A']){
              this.leadTimes['a2A'] = this.leadTimes['c2C'];
            }

            resolve(this.leadTimes);
          }
          else{
            this._commonSubscription.emitAlert({
              theme: Theme.ERROR,
              title: 'Technical Error!',
              message: res['message'],
              ctaLabel: 'OK!'
            })

            reject('response failed')
          }
        })
      }
      
      else{
        return;
      }
    })

    return x;
  }

  getLeadTime(){
    let rideTypeCategory = this._journey.getRideTypeCategory();
    return this.leadTimes[rideTypeCategory];
  }

  setIsAdminUser(ssoId){
    this.isAdminUser = this.superAdminList.includes(ssoId.toString()) ? true : false;

  }

  getIsAdminUser(){
    return this.isAdminUser;
  }

  async setAllowedRideTypesDetails(){
    this._loader.show();
    this.allowedRideTypesDetails = [];
    let allRideTypes = await this._countryFeature.getCountryFeatureList()['defaultActiveRideTypes'];

    
    allRideTypes.forEach(rideTypeDetails=>{
      if(this.allowedRideTypes.includes(rideTypeDetails.code)){
        this.allowedRideTypesDetails.push(rideTypeDetails);
      }
      else{
        return;
      }
    });
    this._loader.hide();
  }

  getAllowedRideTypesDetails(){
    return this.allowedRideTypesDetails;
  }

  setAllowedRideTypes(rideTypes, isSharedRideEnabled = false){
    this.allowedRideTypes = rideTypes;
    isSharedRideEnabled ? this.allowedRideTypes.push('SHARED_RIDE') : null;
  }

  getAllowedRideTypes(){
    return this.allowedRideTypes;
  }

  setAllCarVariants(){
    this._loader.show();
    this.allCarVariants = this._countryFeature.getCountryFeatureList()['carVariants'];
    this._loader.hide();
  }

  getAllCarvariantDetails(){
    return this.allCarVariants;
  }

  setDefaultAgmCoordinates(){
    this._loader.show();
    this.defaultAgmCoordinates = this._countryFeature.getCountryFeatureList()['defaultCoordinates'];
    this._loader.hide();  
  }

  getDefaultAgmCoordinates(){
    return this.defaultAgmCoordinates;
  }

  getCarvariantDetails(selectedPackageCategories, priceMapIds){
    this.allCarVariants.forEach(variant=>{
            priceMapIds.forEach((mapId,index)=>{
              if(Object.keys(priceMapIds[index])[0] == variant.category){
                variant.priceMapId = mapId[variant.category];
              }
              else{
                return;
              }
            })
        });
    let exportPackageDetails = this.allCarVariants.filter(val=>selectedPackageCategories.includes(val.category));
    return exportPackageDetails;
  }

  getZoneBasedCarVariants(){
    let zoneId = this._journey.getPickupLocationZoneId();
    let newCarVaraints =[];
    for(let i = 0; i < this.allCarVariants.length; i++){
      if(this.allCarVariants[i].activeZoneId.includes(zoneId)){
        newCarVaraints.push(this.allCarVariants[i]);
      }
      else{
        null;
      }
    }
    return newCarVaraints;
}

  setCallingCodeList(){
    this._loader.show()
    this._http.get(API_URL_GET_COUNTRY_CODES).subscribe(res=>{
      this._loader.hide();
      if(res['statusCode'] == 200){
        let tempCodeList = res['response']['countryCodePairList'].map(code=>{
            let tempLabel = code['countryNameInitial'] == '' ? 'Mostly Searched' : code['countryNameInitial'] ;
            let tempValue =  code['countryNameInitial'] == '' ? 'Mostly Searched' : code['countryNameInitial'] ;
            let tempItems = code['countries'].map(country=>{
              return {label: country.name, value: country.callingCode, flag: country.countryFlag, limit: country.phoneNumberLimit}
            });
          
            return {label: tempLabel, value: tempValue, items: tempItems}
        })

        this.callingCodeList = tempCodeList;
      }
      else{
        this._commonSubscription.emitAlert({
          theme: Theme.ERROR,
          title: 'Technical Error!',
          message: res['message'],
          ctaLabel: 'OK!'
        })
      }
    });
  }


  getCallingCodeList(){
    if(this.callingCodeList.length <= 0){
      this.setCallingCodeList();
      return this.callingCodeList; 
    }
    else{
      return this.callingCodeList;
    }
  }


    clearAllCarVariants(){
     this.getAllCarvariantDetails();
    }

    scrollTop(percentage: number = 0): void {
      const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPosition = scrollableHeight * (percentage / 100); // Calculate percentage of scroll height
      
      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth'
      });
    }
  
}


