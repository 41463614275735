<div class="contained-container" (click)="onClick()" [ngClass]="{'active-contained-container': !buttonDetails.disabled, 'disabled-contained-container': buttonDetails.disabled}" *ngIf="buttonDetails.type == 'FILLED'">
    <span class="left-container">
        <img [src]="buttonDetails.leftUrl" alt="">
    </span>
    <button class="cta">{{buttonDetails.label}}</button>
    <span class="right-container">
        <img [src]="buttonDetails.rightUrl" alt="">  
    </span>
</div>



<div class="outlined-container" (click)="onClick()" [ngClass]="{'active-outlined-container': !buttonDetails.disabled, 'disabled-outlined-container': buttonDetails.disabled}" *ngIf="buttonDetails.type == 'OUTLINED'">
    <span class="left-container">
        <img [src]="buttonDetails.leftUrl" alt="">
    </span>
    <div class="center-container">
        <img [src]="buttonDetails?.centerUrl" alt="" *ngIf="buttonDetails?.center">
        <button class="cta" [ngStyle]="{'font-weight': weight}">{{buttonDetails.label}}</button>
    </div>
    <span class="right-container">
        <img [src]="buttonDetails.rightUrl" alt="">  
    </span>
</div>
