import { NgxSpinnerService } from "ngx-spinner";
import { CookieService } from 'ngx-cookie-service';
import { AppService } from './services/app.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationStart, Router} from '@angular/router';
import { COOKIE_URL,COOKIE_SUFFIX } from './config/apiRouteConfig';
import { BusinessDetailsService } from './services/business-details/business-details.service';
import { CommonService } from "./app-v2/getter-setter/common.service";
import { CountryFeatureListService } from "./app-v2/localization/country-feature-list/country-feature-list.service";
import { PaymentDetailsContainerService } from "./app-v2/getter-setter/container/create-ride/payment-details-container.service";
import { Subscription } from "rxjs";
import { CommonSubscriptionService } from "./app-v2/subscriptions/common/common-subscription.service";
import { SharedRidePaymentDetailsService } from "./app-v2/getter-setter/container/shared-ride/shared-ride-payment-details.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  ssoId:any;
  delayMsg:string='';
  moreMargin:boolean = true;
  isHelpDialogVisible = false;
  showDelayAlert:boolean = false;
  rideDetailsForHelp  = {
    rideId : null,
    rideRequestId : null
  }
  hideAlert: boolean = false;
  title: any;
  delayAlertSubscription : Subscription;

  constructor(public appService: AppService, private router: Router, private _ckService: CookieService, private _common: CommonService,
    private loader: NgxSpinnerService,
    private _commonSubscription: CommonSubscriptionService,
    private businessDetails : BusinessDetailsService,
    private _countryFeature: CountryFeatureListService,
    private _paymentDetails: PaymentDetailsContainerService,
    private _sharedRidePaymentDetails: SharedRidePaymentDetailsService)
    {
      this.router.events.forEach((event) => {
        if(event instanceof NavigationStart) {
          if(event.url === '/setting' || event.url === '/admin-setting'){
            this.hideAlert = true;
          }else{
            this.hideAlert = false;
          }
        }
      });
      window.location.pathname === "/info" ? this.moreMargin = true : this.moreMargin = false;
  }
  

  ngOnInit() {
    this.startDelayAlertSubscription();
    this.appService.isLoad = false;
    this.appService.isValid = false;
    this.loader.show();
     //setting default zoneId, if not present 
    //  if(localStorage.getItem("zoneIds")==null)
    //  localStorage.setItem("zoneIds","1");
    this.appService.checkToken().subscribe(
      (res) => {
        this.loader.hide();
        this.appService.isLoad = true;
        if (res['statusCode'] != 200) { // unsuccessfull
          this.appService.isValid = false;
          localStorage.removeItem('accessToken');
          if(this.router.url == '/resetpwd?accntLocked=true' || this.router.url == '/resetpwd?accntLocked=false' || this.router.url == '/callback' )
              this.router.navigateByUrl(this.router.url);
          else
            this.router.navigateByUrl('/login');
          
        } else {
       
          this.ssoId = res['response'].userDetails.ssoId
          if(res['response'].balanceDetails){
            localStorage.setItem('walletBalance',res['response'].balanceDetails.currentBalance)
            this.businessDetails.setWalletBalance(res['response'].balanceDetails.currentBalance);
          }
          localStorage.setItem('ssoId',res['response'].userDetails.ssoId)
          localStorage.setItem('email',res['response'].userDetails.email)
          localStorage.setItem('phoneNo',res['response'].userDetails.phone)
          localStorage.setItem('headerImg',res['response'].accountInfo.businessPictureUrl)
          localStorage.setItem('businessAccountCode', res['response'].accountInfo.code)
          localStorage.setItem('name',res['response'].userDetails.firstName)
        
          if(res['response'].accountInfo.businessAccountDataObj && res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto){
                        localStorage.setItem('isShowShared',res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideEnabled)
                        localStorage.setItem('isShowSharedNew',res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideNewFeatureTag)
            res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto?.rosterUploadEnabled ? localStorage.setItem('showRosterUpload',res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto.rosterUploadEnabled) : localStorage.setItem('showRosterUpload', 'false'); 
          }
          if(res['response']?.accountInfo?.businessAccountDataObj && res['response']?.accountInfo?.businessAccountDataObj?.featureDetailsDataDto?.sharedRideMaxAllowedRiders){
            this.businessDetails.setSharedRideMaxLimit(res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideMaxAllowedRiders);
          }
          if(res['response']?.accountInfo?.businessAccountDataObj && res['response']?.accountInfo?.businessAccountDataObj?.featureDetailsDataDto?.sharedRideMinAllowedRiders){
            this.businessDetails.setSharedRideMinLimit(res['response'].accountInfo.businessAccountDataObj.featureDetailsDataDto.sharedRideMinAllowedRiders);
          }
          if(res['response'].accountInfo['managerApprovalRequired'] != null){
            this.businessDetails.setIsManagerApprovalEnabledFlag(res['response'].accountInfo['managerApprovalRequired']);
          }
          this.businessDetails.setAcceptedPaymentType(res['response'].accountInfo.allowedPaymentMode)
          this.businessDetails.setAllowedRideType(res['response'].accountInfo.allowedRideType)
          this.businessDetails.setPricingModel(res['response'].accountInfo.pricingModel)
          this.businessDetails.setBusinessName(res['response'].accountInfo.businessDisplayName);
          this.businessDetails.setHeaderImgUrl(res['response'].accountInfo.businessPictureUrl);


          let isSharedRideEnabled = res['response']?.accountInfo?.businessAccountDataObj?.featureDetailsDataDto?.sharedRideEnabled;
          this._countryFeature.setCountryFeatureList(this.appService.getCountry());
          this._common.setCallingCodeList();
          this._common.setAllowedRideTypes(res['response'].accountInfo.allowedRideType, isSharedRideEnabled);
          this._paymentDetails.setAllowedPaymentModes(res['response'].accountInfo.allowedPaymentMode);
          this._sharedRidePaymentDetails.setAllowedPaymentModes(["BUSINESS_ACCOUNT"])
          this._common.setSelectedCountry(this.appService.getCountry());
          

          this.appService.getDelayAlerts().subscribe(
            (res: any) => {
              let delayData = res.response;
              if(delayData != null){
              if(delayData['isAppOutageAlert'] || delayData.isHighDemandAlert || delayData.isRainDelayAlert){
                this.showDelayAlert = true;
                this.delayMsg = delayData.isAppOutageAlert ? 'Due to a technical glitch, the app has been facing an outage. Our team has been facing an outage. Our team has identified the app up & running as soon as possible. We apologies for the inconvenience & thank you for your patience during this time.' :
                 delayData.isHighDemandAlert ? "We're facing unusually high demand at the moment. Hence, there might be a delay in your ride. We'll try to serve you as soo as possible. Thank you for your patience." :
                  delayData.isRainDelayAlert ? 'Due to waterlogging in many areas of Delhi NCR, your ride might be delayed or cancelled. We are trying our best to serve you during these challenging weather conditions. Thank you for your patience.': '';
              }
              else{
                this.showDelayAlert = false;
              }
            }
            else{
              return ;
            }
            }
          )
          
          this.appService.getRolesForUser(this.ssoId).subscribe(
            (res: any) => {
              localStorage.setItem("userRoles",JSON.stringify(res.response))
              this.appService.isValid = true;
            }
          )
          

        }
        
      },
      (err) => {
        //console.log(err);

      }
    )
  }

  marginChangeHandler(margin:any){
    if(margin>100){
      this.moreMargin=true;
    }else{
      this.moreMargin=false;
    }

  }

  openHelpDialog(){
    this.isHelpDialogVisible = true;
  }

  closeHelpDialog(){
    this.isHelpDialogVisible = false;
  }

  startDelayAlertSubscription(){
    this.delayAlertSubscription = this._commonSubscription.fetchDelayAlert().subscribe(res=>{
      if(res){
        setTimeout(()=>{
          this.hideAlert = !res;
        },3000)
      }
      
      else{
        this.hideAlert = !res;
      }
    })
  }

  ngOnDestroy(): void {
    this.delayAlertSubscription.unsubscribe();
  }

}
